import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
`;

export const ContainerHeaderAndInformation = styled.div`
  margin-bottom: 10px;

  > h4 {
    margin: 0;
  }

  > p {
    display: flex;
    gap: 5px;
    align-items: center;

    @media (max-width: 768px) {
      display: unset;
    }


    > div:last-child {
      margin-bottom: 4px;
    }

    > strong {
      color: #606062;
    }
  }
`;

export const ContainerTerms = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  > label > a {
    color: #009291;
  }

  > checkbox {
    accent-color: #009291;
  }
`;

export const ContainerSelects = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 10px;
  }
`;
