import moment from 'moment';
import arrayIn from 'utils/arrayIn';

export const internalJobOfferPayload = (
  data,
  contextData,
  responseProfession
) => {
  const shifts = ['morning', 'afternoon', 'night'];

  return {
    ...data,
    ...contextData,
    shift: arrayIn(shifts, contextData.shift)
      ? ['indifferent']
      : contextData.shift,
    contact_email: data.contact_email ? data.contact_email : null,
    external: {},
    course_progress: contextData.course_progress
      ? contextData.course_progress
      : 0,
    required_professional_areas: [],
    start_offer_at: moment(data.start_offer_at).format('DD/MM/YYYY'),
    end_offer_at: moment(data.end_offer_at).format('DD/MM/YYYY'),
    required_additional_courses_areas: contextData.required_additional_courses_areas
      ? contextData.required_additional_courses_areas.map((item) => {
          return item.value;
        })
      : [],
    administrative_selection_process: data.administrative_selection_process
      ? data.administrative_selection_process
      : false,
    courses_matching_rule: {
      profession: responseProfession.id
        ? responseProfession.id
        : contextData.id,
      courses: contextData.courses.map((item) => {
        return item.value;
      }),
      semesters: [],
    },
    salary: contextData.salary
      ? contextData.salary.replace('.', '').replace(',', '.')
      : 0,
    required_behavioral_attributes: contextData.required_behavioral_attributes
      ? contextData.required_behavioral_attributes.map((item) => {
          return item.value;
        })
      : [],

    enabled_just_for_universities: contextData.enabled_just_for_universities
      ? Array.isArray(contextData.enabled_just_for_universities)
        ? contextData.enabled_just_for_universities
        : [contextData.enabled_just_for_universities]
      : [],

    gender_identity: data.gender_identity ? data.gender_identity : null,
    disability: data.disability ? data.disability : null,
    color_or_race: data.color_or_race ? data.color_or_race : null,

    candidates: [],
    finished_at: null,
  };
};
