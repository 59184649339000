import {
  deleteFairAndEventsSubscribe,
  getFairAndEventsDetails,
  getFairAndEventsListings,
  getFairAndEventsSubscribeDetails,
  postFairAndEventsSubscribeStudent,
  verifyFairAndEventsSubscribeStudent,
} from 'views/Student/Learning/Services';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { DelaySearchHelper } from '../Helpers/DelaySearchHelper';

export const VisualizationHandler = {
  subscribeStudent: async (visuzationContextInstance, eventId) => {
    const { getListings } = visuzationContextInstance;

    const response = await postFairAndEventsSubscribeStudent(eventId);
    getListings();

    customSnackbar('Inscrição realizada com sucesso!', 'confirmation');
  },
  openTicketModal: async (visualizationContextInstance, event, id, uuid) => {
    const response = await getFairAndEventsSubscribeDetails(id, uuid);

    const {
      setModalTicketOpen,
      setModalTicketData,
    } = visualizationContextInstance;

    setModalTicketData({ ...event, ...response });
    setModalTicketOpen(true);
  },
  openDetailsModal: async (visualizationContextInstance, id, uuid) => {
    const response = await getFairAndEventsDetails(id);

    const {
      setDetailsModalOpen,
      setDetailsObject,
    } = visualizationContextInstance;
    setDetailsObject({ ...response, uuid: uuid });
    setDetailsModalOpen(true);

    return response;
  },
  onConfirmCancelSubscribe: async (visualizationContextInstance) => {
    const { detailsObject, getListings } = visualizationContextInstance;

    const { id, uuid } = detailsObject;

    const response = await deleteFairAndEventsSubscribe(id, uuid);
    getListings();
  },
  changeVisualizationToFavorites: async (
    visualizationContextInstance,
    value
  ) => {
    const {
      setGetFavoritedAvaiables,
      setAvailables,
    } = visualizationContextInstance;
    setGetFavoritedAvaiables(value);
    const response = await getFairAndEventsListings('opened', '', null, value);
    setAvailables({ value: response });
  },

  changeLivestreamModalOpen: async (visualizationContextInstance) => {
    const {
      setLivestreamModalOpen,
      setModalTicketOpen,
    } = visualizationContextInstance;
    setModalTicketOpen(false);
    setLivestreamModalOpen((prevs) => !prevs);
  },

  verifyFairAndEvents: async (visuzationContextInstance, eventId) => {
    const { getListings } = visuzationContextInstance;
    const response = await verifyFairAndEventsSubscribeStudent(eventId);
    getListings();
    if (!response.in_registration_period) {
      customSnackbar('Prazo de inscrição expirado', 'error');
    }
    return response.in_registration_period;
  },
};
