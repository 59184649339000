import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > form > div {
    display: flex;
  }

  > form {
    width: 100%;
  }

  >h4 {
    width: 100%;
  }
`;


export const ContainerAddress = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperRowsInput = styled.div`
  display: flex;
  gap: 10px;
  margin-top: ${({ marginTop }) => marginTop || '16px'};
`;

export const FieldLabel = styled.label`
  color: #6d6d6d;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
`;

export const WrapperLabelAndHint = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  > div {
    margin-bottom: 1px;
  }
`;

export const InputFieldWrapper = styled.div`
  flex: ${({ flex }) => flex || '1'};
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '0'};
`
