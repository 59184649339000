import learningBanner from 'assets/img/oportunidades.png';
import { useState, useEffect, useCallback, useMemo } from 'react';
import CompanyViewsHeader from 'components/ViewsHeader/CompanyViewsHeader';
import Learning from './components/Screens/Learning';
import ExtesionProjectEngine from './components/Screens/ExtensionProject';
import ListLearningProvider from './Context/LearningContext';
import {
  cancelGetAllLearnings,
  getCount,
  getFairAndEventsFinishedCount,
  getFairAndEventsListingsCount,
} from './Services';
import {
  cancelGetPublishedExtensions,
  getCountEnrolled,
} from 'services/student/extensionProject';
import { SelfDevelopment } from './components/Screens/SelfDevelopment';
import CurriculumContextProvider from 'contexts/CurriculumContext';
import FairAndEventsEngine from './components/Screens/FairAndEvents/FairAndEventsEngine';
import { VisualizationProvider } from './components/Screens/FairAndEvents/Contexts/VisualizationContext';

export default function MainComponent(props) {
  const {
    handleIsLoadingState,
    history,
    academic_profile,
    location,
    match,
    userData,
    isExtension = false,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [count, setCount] = useState(0);
  const [favoritesTabCount, setFavoritesTabCount] = useState(0);
  const [finishedTabCount, setFinishedTabCount] = useState(0);
  const [availableTabCount, setAvailableTabCount] = useState(0);
  const [filterState, setFilterState] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [fairAndEventsCount, setFairAndEventsCount] = useState(0);

  const [countAllExtensionProject, setCountAllExtensionProject] = useState(
    null
  );
  const [countOpenedExtesionProject, setCountOpenedExtesionProject] = useState(
    0
  );
  const [
    countEnrolledExtesionProject,
    setCountEnrolledExtesionProject,
  ] = useState(0);

  const [headerTabs, setHeaderTabs] = useState(() => [
    `Cursos (${count})`,
    `Feiras e Eventos (${fairAndEventsCount || 0})`,
  ]);

  const updateHeaderTabs = useCallback(() => {
    setHeaderTabs([
      `Cursos (${count})`,
      `Feiras e Eventos (${fairAndEventsCount || 0})`,
    ]);
  }, [count, countAllExtensionProject, fairAndEventsCount, userData]);

  useEffect(() => {
    updateHeaderTabs();
  }, [updateHeaderTabs]);

  const learningCountControl = useCallback(async () => {
    const response = await getCount();
    setCount(response.total_attractions);
    setFavoritesTabCount(response.favorites_count);
    setAvailableTabCount(response.available_count);
    setFinishedTabCount(response.finished_count);
  }, []);

  const extensionProjectCount = useCallback(async () => {
    const { projects_sum, opened, enrolled } = await getCountEnrolled();
    setCountAllExtensionProject(projects_sum);
    setCountOpenedExtesionProject(opened);
    setCountEnrolledExtesionProject(enrolled);
  }, []);

  useEffect(() => {
    if (location?.state?.tab === 2) {
      setActiveTab(userData.metadata.has_extension_project ? 2 : 1);
      learningCountControl();
      handleIsLoadingState(false);
    }
  }, [location.state, learningCountControl, handleIsLoadingState, userData]);

  useEffect(() => {
    const getCountsFairAndEvent = async () => {
      const fairAndEventsListingsCount = await getFairAndEventsListingsCount();
      const {
        finished,
        availables,
        my_registrations,
      } = fairAndEventsListingsCount;
      const fullCount = finished + availables + my_registrations;
      setFairAndEventsCount(fullCount);
    };
    getCountsFairAndEvent();
  }, []);

  useEffect(() => {
    if (location?.state?.tab === undefined) {
      learningCountControl();
      handleIsLoadingState(false);
    }
  }, [location?.state, learningCountControl, handleIsLoadingState]);

  useEffect(() => {
    if (location?.state?.externalTab) {
      setActiveTab(location?.state?.externalTab);
      delete location?.state?.externalTab;
    }
  }, [location?.state]);

  const handleTabChange = (_, value) => {
    setActiveTab(value);
    if (value !== 0) {
      cancelGetPublishedExtensions();
    }
    if (value !== 0 || value !== 1) {
      cancelGetAllLearnings();
    }
  };

  const tabs = useMemo(
    () => [
      {
        activeTab: 0,
        component: (
          <Learning
            favoritesTabCount={favoritesTabCount}
            finishedTabCount={finishedTabCount}
            availableTabCount={availableTabCount}
            learningCountControl={learningCountControl}
            extensionProjectCount={extensionProjectCount}
            filterState={filterState}
            filterOptions={filterOptions}
            count={count}
            activeTab={activeTab}
            academic_profile={academic_profile}
            history={history}
            location={location}
            metadata={userData.metadata}
          />
        ),
      },
      {
        activeTab: 1,
        component: (
          <VisualizationProvider>
            <FairAndEventsEngine
              eventIdRoute={match?.params?.eventId}
              eventIdFromRequest={match?.params?.fairEventId}
              eventRegisterId={match?.params?.registerId}
              academic_profile={academic_profile}
              setFairAndEventsCount={setFairAndEventsCount}
              location={location}
            />
          </VisualizationProvider>
        ),
      },
    ],
    [
      userData,
      countAllExtensionProject,
      countOpenedExtesionProject,
      countEnrolledExtesionProject,
      extensionProjectCount,
      fairAndEventsCount,
      count,
      favoritesTabCount,
      finishedTabCount,
      availableTabCount,
      activeTab,
      filterState,
      filterOptions,
      academic_profile,
      history,
      location,
      match,
    ]
  );

  const renderPrincipalTabs = (step) => {
    const returnTabs = tabs.find((tab) => tab.activeTab === step);
    return returnTabs?.component || tabs[step]?.component;
  };

  useEffect(() => {
    if (match?.params?.eventId) {
      setActiveTab(1);
    }
  }, [match?.params?.eventId]);

  useEffect(() => {
    if (match?.params?.fairEventId) {
      setActiveTab(1);
    }
  }, [match?.params?.fairEventId]);

  return (
    <CurriculumContextProvider>
      <ListLearningProvider
        setFavoritesTabCount={setFavoritesTabCount}
        setFinishedTabCount={setFinishedTabCount}
        setAvailableTabCount={setAvailableTabCount}
        setFilterState={setFilterState}
        setFilterOptions={setFilterOptions}
      >
        {isExtension ? (
          <>
            <CompanyViewsHeader
              title="Projetos de Extensão"
              activeTab={activeTab}
              handleTabChange={() => null}
              backgroundImage={learningBanner}
              tabs={null}
            />
            <ExtesionProjectEngine
              countExtesionProject={countAllExtensionProject}
              countOpenedExtesionProject={countOpenedExtesionProject}
              countEnrolledExtesionProject={countEnrolledExtesionProject}
              extensionProjectCount={extensionProjectCount}
              setActiveTab={setActiveTab}
            />
          </>
        ) : (
          <>
            <CompanyViewsHeader
              title="Cursos e Eventos"
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              backgroundImage={learningBanner}
              tabs={headerTabs}
            />
            {renderPrincipalTabs(activeTab)}
          </>
        )}
      </ListLearningProvider>
    </CurriculumContextProvider>
  );
}
