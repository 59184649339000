import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const getBackgroundColorSnackbar = {
  error: '#FFEDED',
  confirmation: '#C6FCD4',
  alert: '#FFF9CB',
  loading: '#E5E5E5',
};

export const getColorSnackBar = {
  error: '#DA0505',
  confirmation: '#048629',
  alert: '#DEAD00',
  loading: '#606062',
};

export const SnackbarContainer = styled.div`
  position: fixed;
  bottom: 85px;
  left: 20px;
  min-height: 56px;
  background-color: ${(props) => getBackgroundColorSnackbar[props.typeMessage]};
  color: ${(props) => getColorSnackBar[props.typeMessage]};
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 4px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translateY(100%);
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 0.5s ease;
  z-index: 99999999999;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  max-width: 600px;

  gap: 15px;

  p {
    margin: 0;
  }

  @media (max-width: 620px) {
    bottom: 75px;
    max-height: 72px;
    left: 0;
    max-width: 100%;
  }

  @media (max-width: 428px) {
    bottom: 85px;
    max-height: 84px;
    max-width: 100%;
  }

  @media (max-width: 329px) {
    bottom: 105px;
    max-height: 105px;
    max-width: 100%;
  }

  .message-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }

  .message-icon div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
  }

  .close-button {
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    border: none;
  }
`;
