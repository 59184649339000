import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FairAndEventsService from '../Services/index';
import defineUrlFront from 'utils/defineUrlFront';
import BaseLayoutContext from 'contexts/base-layout';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

const VisualizationContext = createContext();

export const VisualizationProvider = ({ children, history }) => {
  const [awaiting, setAwaiting] = useState([]);
  const [onGoing, setOnGoing] = useState([]);
  const [toFinish, setToFinish] = useState([]);
  const [finished, setFinished] = useState([]);
  const [amounts, setAmounts] = useState({});
  const [loading, setLoading] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const [studentViewModalOpen, setStudentViewModalOpen] = useState(false);

  const [detailsObject, setDetailsObject] = useState({});

  const [finishedType, setFinishedType] = useState('finished');

  const [selectedEvent, setSelectedEvent] = useState({});

  const emailToExport = useRef('');

  const [
    modalInformationEmailSentOpen,
    setModalInformationEmailSentOpen,
  ] = useState(false);

  const { openSnackbar, metadata } = useContext(BaseLayoutContext);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
    setFinishedType('finished');
  };

  const paginationObject = {
    0: {
      next: awaiting?.value?.next,
      setter: setAwaiting,
      type: 'awaiting-start',
    },
    1: {
      next: onGoing?.value?.next,
      setter: setOnGoing,
      type: 'on-going',
    },
    2: {
      next: toFinish?.value?.next,
      setter: setToFinish,
      type: 'to-finish',
    },
    3: {
      next: finished?.value?.next,
      setter: setFinished,
      type: 'finished',
    },
  };

  const hasNextPagination = paginationObject[currentTab].next;

  const handleClickLoadMore = async () => {
    const currentPagination = paginationObject[currentTab];

    if (hasNextPagination) {
      setLoading(true);
      const query = currentPagination.next.split('?');
      const response = await FairAndEventsService.getListings(
        currentPagination.type,
        query[1]
      );

      if (currentPagination.type === 'finished') {
        currentPagination.setter((prevs) => ({
          value: {
            ...response,
            results: {
              results: [
                ...(Array.isArray(prevs.value?.results)
                  ? prevs.value?.results
                  : prevs.value?.results?.results),
                ...(Array.isArray(response?.results)
                  ? response.results
                  : response.results.results),
              ],

              amounts: response.results.amounts,
            },
          },
        }));
        setLoading(false);
        return;
      }

      setLoading(false);
      currentPagination.setter((prevs) => ({
        value: {
          ...response,
          results: [
            ...(Array.isArray(prevs.value?.results)
              ? prevs.value?.results
              : prevs.value?.results?.results),
            ...(Array.isArray(response?.results)
              ? response.results
              : response.results.results),
          ],
        },
      }));
    }
  };

  const getListings = async () => {
    const [
      amounts,
      email,
      awaiting,
      onGoing,
      toFinish,
      finished,
    ] = await Promise.allSettled([
      FairAndEventsService.getAmounts(),
      FairAndEventsService.getEmailToSend(),
      FairAndEventsService.getListings('awaiting-start'),
      FairAndEventsService.getListings('on-going'),
      FairAndEventsService.getListings('to-finish'),
      FairAndEventsService.getListings('finished', _, finishedType),
    ]);
    emailToExport.current = email.value.email;
    setAmounts(amounts.value);
    setAwaiting(awaiting);
    setOnGoing(onGoing);
    setToFinish(toFinish);
    setFinished(finished);
  };

  const copyLinkToClipboard = (eventId) => {
    const { university_slug } = metadata;
    const url = `${defineUrlFront()}/#/feiraseeventos/${university_slug}/${eventId}`;
    navigator.clipboard.writeText(url);
    customSnackbar(
      `O link foi copiado com sucesso!
        Apenas seus estudantes conseguirão
        visualizar o evento após fazer o login.
      `,
      'confirmation'
    );
  };

  useEffect(() => {
    getListings();
  }, []);

  return (
    <VisualizationContext.Provider
      value={{
        loading,
        awaiting,
        onGoing,
        toFinish,
        finished,
        handleTabChange,
        currentTab,
        detailsModalOpen,
        setDetailsModalOpen,
        detailsObject,
        setDetailsObject,
        paginationObject,
        hasNextPagination,
        handleClickLoadMore,
        getListings,
        history,
        studentViewModalOpen,
        setStudentViewModalOpen,
        finishedType,
        setFinishedType,
        setCurrentTab,
        emailToExport,
        modalInformationEmailSentOpen,
        setModalInformationEmailSentOpen,
        selectedEvent,
        setSelectedEvent,
        amounts,
        copyLinkToClipboard,
      }}
    >
      {children}
    </VisualizationContext.Provider>
  );
};

export const useVisualizationContext = () => {
  const context = useContext(VisualizationContext);
  if (!context) {
    throw new Error(
      'VisualizationContext must be used within a RegisterModalProvider'
    );
  }
  return context;
};
