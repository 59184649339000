import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 20000000000;
  font-family: Roboto;

  >img {
    max-width: 100%;
    height: auto;
    padding: 0px 20px 0px;
  }

  .legend {
    font-size: 20px;
    font-weight: 400;
    margin-top: 0px;
    color: #969CA6;
  }

  .title {
    font-weight: 700;
    font-size: 40px;
    margin-top: 30px;
    color: #606062;
    padding: 0px 20px 0px;
  }
  
  .text {
    font-weight: 400;
    font-size: 24px;
    margin-top: 20px;
    max-width: 656px;
    text-align: center;
    line-height: 28.13px;
    color: #606062;
    padding: 0px 20px 0px;
  }
`;

export const TechBy = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-top: 21px;

  >p {
    font-weight: 400;
    font-size: 12.5px;
  }

  >img {
    margin-top: 1px;
  }
`

export const BackButton = styled.button`
  width: 249px;
  height: 44px;
  cursor: pointer;
  color: #ffffff;
  background-color: #005096;
  border: none;
  border-radius: 3px;
  display: flex;
  font-weight: 400;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  
  a, a:visited, a:hover, a:active {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 10px 0px 0px;
    color: #ffffff;
  }
`;