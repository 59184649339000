import StepIndicator from 'components/StepIndicator';
import StepOneRegister from './RegisterSteps/StepOneRegister';
import StepTwoRegister from './RegisterSteps/StepTwoRegister';

import { useContext, useEffect, useRef, useState } from 'react';
import {
  ButtonNext,
  ButtonPrevious,
  ButtonSave,
  WrapperButton,
  WrapperRegister,
  HeaderContainer,
  HeaderText,
  IconContainer,
  IconWrapper,
  IconCircle,
  IconText,
  Button,
} from './style';
import { JobOfferContext } from 'views/JobOfferRebuild/Contexts/JobOfferContext';
import {
  getOptions,
  getPartialRegisterStatus,
  optionsMacroAreas,
  saveCompanyNew,
} from 'views/CompanyRebuild/Services';
import { PayloadCreateCompany } from 'views/CompanyRebuild/helpers/payload';
import * as Yup from 'yup';
import AuthContext from 'contexts/authentication';
import { login } from 'services/login/authenticate';
import { CiClock2 } from 'react-icons/ci';
import { HiOutlineBriefcase } from 'react-icons/hi2';
import { isStaffEmailExists } from 'services/companies';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import BaseLayoutContext from 'contexts/base-layout';
import { getTermCompanyNotAuthenticate } from 'services/acceptanceTerm';
import { actionsLocationStore, useLocationStore } from 'stores/locationStore';

export default function RegisterEngine(props) {
  const { universityColor: colorFromUniversity } = props?.location?.state || {};

  const { locationStore } = useLocationStore((state) => state.locationStore);

  const [objectSteps, setObjectSteps] = useState({});
  const { updateLocationStore } = actionsLocationStore;

  const universityColor =
    colorFromUniversity?.length > 0 ? colorFromUniversity : '#009291';

  const status = props?.location?.state?.status;

  if (!status) {
    const universitySlug = props?.match?.params?.id
      ? props?.match?.params?.id
      : '';
    window.location.href = `#/login/empresa/${universitySlug}`;
  }

  const formRefStepOneRegister = useRef();
  const formRefStepTwoRegister = useRef();

  const refs = {
    1: formRefStepOneRegister,
    2: formRefStepTwoRegister,
  };

  const { toggleSidebarVisibility } = useContext(BaseLayoutContext);

  const [currentStep, setCurrentStep] = useState(1);
  const [onUserAuthenticate, setOnUserAuthenticate] = useState({});

  const [successRegister, setSuccessRegister] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const { setCompanyValues, companyData } = useContext(JobOfferContext);

  const [options, setOptions] = useState([]);
  const [macroAreaOptions, setMacroAreaOptions] = useState([]);
  const [termsLink, setTermsLink] = useState('');

  const scrollRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const getAllOptions = async (e) => {
    e && e.preventDefault();
    const [macroAreas, options, terms] = await Promise.allSettled([
      optionsMacroAreas(),
      getOptions(),
      getTermCompanyNotAuthenticate(),
    ]);

    setMacroAreaOptions(macroAreas.value);
    setOptions(options.value);
    setTermsLink(terms?.value?.file_term);
  };

  useEffect(() => {
    getAllOptions();
  }, []);

  useEffect(() => {
    async function getPartialData(params) {
      const response = await getPartialRegisterStatus(
        locationStore.organizationNumber
      );
      setObjectSteps(response.data);
    }

    if (locationStore.status === 'partial') {
      getPartialData();
    }
  }, [locationStore]);

  useEffect(() => {
    toggleSidebarVisibility();
  }, []);

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const isLastStep = currentStep === 2;

  const schemaStepOne = Yup.object().shape({
    staff_name: Yup.string()
      .required('Campo obrigatório')
      .test(
        'nome e sobrenome',
        'Nome e sobrenome são obrigatórios',
        (value) => {
          const names = value.split(' ');
          return (
            names.length > 1 && names[0].trim() !== '' && names[1].trim() !== ''
          );
        }
      ),
    email: Yup.string()
      .email('Informe um e-mail válido')
      .required('Campo obrigatório')
      .test('email', 'E-mail já está em uso', async (value) => {
        if (!value) {
          return new Yup.ValidationError(
            'Essa informação é obrigatória',
            null,
            'email'
          );
        }

        const output = await isStaffEmailExists(value);
        const { exists } = output;

        return !exists;
      }),
    phone: Yup.string()
      .required('Informação Obrigatória')
      .test('phone', 'Telefone inválido', (value) => {
        if (!value) {
          return new Yup.ValidationError(
            'Essa informação é obrigatória',
            null,
            'phone'
          );
        }
        const phone = value.replace(/[^\d]+/g, '');
        return (
          phone.length === 10 || phone.length === 11 || phone.length === 15
        );
      }),
    staff_role: Yup.string().required('Campo obrigatório'),
    staff_sector: Yup.string().required('Campo obrigatório'),
    staff_password: Yup.string().required('Campo obrigatório'),
    staff_password_confirmation: Yup.string()
      .oneOf([Yup.ref('staff_password'), null], 'Senhas não correspondem')
      .required('Campo obrigatório'),
  });

  const schemaStepTwo = Yup.object().shape({
    organization_number: Yup.string()
      .required('Informação Obrigatória')
      .test('cnpj', 'CNPJ inválido', (value) => {
        if (!value) return false;
        const cnpj = value.replace(/[^\d]+/g, '');
        if (cnpj.length !== 14) return false;

        let length = cnpj.length - 2;
        let numbers = cnpj.substring(0, length);
        const digits = cnpj.substring(length);
        let sum = 0;
        let pos = length - 7;

        for (let i = length; i >= 1; i--) {
          sum += numbers.charAt(length - i) * pos--;
          if (pos < 2) pos = 9;
        }

        let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result !== parseInt(digits.charAt(0))) return false;

        length += 1;
        numbers = cnpj.substring(0, length);
        sum = 0;
        pos = length - 7;

        for (let i = length; i >= 1; i--) {
          sum += numbers.charAt(length - i) * pos--;
          if (pos < 2) pos = 9;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result !== parseInt(digits.charAt(1))) return false;

        return true;
      }),
    legal_name: Yup.string().required('Informação Obrigatória'),
    name: Yup.string().required('Informação Obrigatória'),
    type: Yup.string().required('Informação Obrigatória'),
    size: Yup.string().required('Informação Obrigatória'),
    zip_code: Yup.string().required('Informação Obrigatória'),
    address: Yup.string().required('Informação Obrigatória'),
    address_number: Yup.string().required('Informação Obrigatória'),
    district: Yup.string().required('Informação Obrigatória'),
  });

  const nextStep = async () => {
    const currentForm = refs[currentStep];
    const currentFormValues = currentForm.current.getData();

    if (currentStep === 1) {
      try {
        await schemaStepOne.validate(currentFormValues, { abortEarly: false });
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          currentForm.current.setErrors(validationErrors);
        }
        return;
      }
    }

    if (!isLastStep) {
      setCurrentStep((prev) => prev + 1);
      setCompanyValues({ ...currentFormValues });
    }
  };

  const handleSubmitCreateCompany = async (functionLogin) => {
    const formData = formRefStepTwoRegister?.current?.getData();
    formRefStepTwoRegister.current.setErrors({});
    try {
      setLoading(true);
      await schemaStepTwo.validate(formData, { abortEarly: false });
      await saveCompanyNew(
        PayloadCreateCompany({
          ...formData,
          ...companyData,
        })
      );
      updateLocationStore('status', 'complete')
      setSuccessRegister(true);

      const loginUrl = '/login/empresa';
      const userData = {
        username: companyData.email,
        password: companyData.staff_password,
        company: true,
      };
      const loginUser = await login(loginUrl, userData);
      setOnUserAuthenticate(loginUser);
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRefStepTwoRegister.current.setErrors(validationErrors);
      }
      customSnackbar('Erro ao cadastrar empresa', 'error');
    }
  };

  const handleUserLogin = (loginUser, functionLogin) => {
    if (onUserAuthenticate.isLogged || loginUser.isLogged) {
      functionLogin(
        onUserAuthenticate.data ? onUserAuthenticate.data : loginUser.data
      );
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const buttonDisabled = () => {
    if (loading) {
      return true;
    }

    if (termsAccepted && passwordsMatch) {
      return false;
    }
    return true;
  };

  const stepsSteps = {
    1: (
      <StepOneRegister
        {...props}
        formRef={formRefStepOneRegister}
        options={options}
        macroAreaOptions={macroAreaOptions}
        termsLink={termsLink}
        objectSteps={objectSteps}
        setPasswordsMatch={setPasswordsMatch}
      />
    ),
    2: (
      <StepTwoRegister
        {...props}
        options={options}
        formRef={formRefStepTwoRegister}
        objectSteps={objectSteps}
        status={status}
        setObjectSteps={setObjectSteps}
        setTermsAccepted={setTermsAccepted}
        termsAccepted={termsAccepted}
        termsLink={termsLink}
      />
    ),
  };

  const buttons = () => {
    if (currentStep === 1) {
      return (
        <WrapperButton justifyContent="flex-end">
          <ButtonNext
            type="button"
            universityColor={universityColor}
            onClick={nextStep}
          ></ButtonNext>
        </WrapperButton>
      );
    }
    if (currentStep === 2) {
      return (
        <WrapperButton justifyContent="space-between">
          <ButtonPrevious
            universityColor={universityColor}
            onClick={prevStep}
          ></ButtonPrevious>
          <AuthContext.Consumer>
            {({ routerOnUserLogin }) => (
              <ButtonSave
                disabled={buttonDisabled()}
                universityColor={universityColor}
                onClick={() => handleSubmitCreateCompany(routerOnUserLogin)}
              >
                Finalizar cadastro
              </ButtonSave>
            )}
          </AuthContext.Consumer>
        </WrapperButton>
      );
    }
  };

  useEffect(() => {
    if (successRegister) {
      scrollRef?.current?.scrollIntoView();
    }
  }, [successRegister]);

  return (
    <>
      <HeaderContainer universityColor={universityColor}>
        <HeaderText>
          Encontre os <strong>melhores estudantes</strong> para as suas vagas de
          forma rápida e assertiva.
        </HeaderText>

        <IconContainer>
          <IconWrapper>
            <IconCircle>
              <CiClock2 size={22} color={universityColor || '#009291'} />
            </IconCircle>
            <IconText>
              Crie sua conta em menos de <strong>1 minuto</strong>
            </IconText>
          </IconWrapper>

          <IconWrapper>
            <IconCircle>
              <HiOutlineBriefcase
                size={22}
                color={universityColor || '#009291'}
              />
            </IconCircle>
            <IconText>
              Cadastre uma vaga em menos de <strong>2 minutos</strong>
            </IconText>
          </IconWrapper>
        </IconContainer>
      </HeaderContainer>
      <WrapperRegister succesRegister={successRegister}>
        {successRegister ? (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <h3 ref={scrollRef} style={{ color: universityColor }}>
              Seja bem-vindo!
            </h3>
            <p style={{ fontSize: '16px' }}>
              Agora você pode contratar estudantes e recém-formados mais
              alinhados aos seus valores e suas exigências técnicas e
              comportamentais.
            </p>
            <AuthContext.Consumer>
              {({ routerOnUserLogin }) => (
                <Button
                  width="200px"
                  universityColor={universityColor}
                  onClick={() =>
                    handleUserLogin(onUserAuthenticate, routerOnUserLogin)
                  }
                >
                  Vamos lá
                </Button>
              )}
            </AuthContext.Consumer>
          </div>
        ) : (
          <>
            <StepIndicator
              count={2}
              currentStep={currentStep}
              universityColor={universityColor}
              onStepChange={handleStepChange}
            />
            {stepsSteps[currentStep]}

            {buttons()}
          </>
        )}
      </WrapperRegister>
    </>
  );
}
