import * as React from 'react';

const RightArrow = ({ width = 24, height = 16, color = '#FFF' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      fill={color}
      d="M23.428 7.754c-.198-.29-.36-.617-.602-.863-1.994-2.032-4.003-4.05-6.009-6.07-.533-.538-1.231-.585-1.734-.127-.536.489-.548 1.28-.012 1.824 1.306 1.325 2.618 2.645 3.927 3.967.076.077.147.157.268.286H1.937c-.68 0-1.139.305-1.31.868-.231.755.29 1.53 1.07 1.586.11.008.221.004.332.004h17.238c-.116.124-.19.208-.27.288q-1.962 1.984-3.93 3.965c-.355.358-.476.785-.337 1.264.132.458.453.747.915.845.452.097.83-.062 1.152-.388q2.2-2.221 4.403-4.44c.544-.549 1.102-1.085 1.626-1.653.235-.255.403-.574.602-.863z"
    ></path>
  </svg>
);

export default RightArrow;
