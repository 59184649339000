import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { H4Header, ContainerMiddleInformations } from '../styled';
import { BsLink45Deg } from 'react-icons/bs';
import Button from '../../Button';

export default function Competencies() {
  const { detailsObject, finishedType, copyLinkToClipboard } = useVisualizationContext();

  const { id, associated_areas } = detailsObject;

  const createCompetencies = () => {
    return associated_areas.map((area) => {
      return <li>{area.label}</li>;
    });
  };

  return (
    <>
      <H4Header>Competências trabalhadas no evento</H4Header>

      <ul>{createCompetencies()}</ul>

      {finishedType !== "canceled" &&
        <ContainerMiddleInformations>
          <div>
            <div>
              <BsLink45Deg size={30} color="#009291" />

              <span>
                <h6>Link da publicação</h6>
                <Button
                  onClick={() => copyLinkToClipboard(id)}
                  theme="flatUniversityColor"
                  >
                  Clique para copiar
                </Button>
              </span>
            </div>
          </div>
        </ContainerMiddleInformations>
      }
    </>
  );
}
