import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 28px;
`;
export const HeaderTitle = styled.h4`
  color: #009291;
  font-weight: 400;
`;

export const WrapperPeriod = styled.div`
  margin-top: 40px;
`;

export const Title = styled.h5`
  font-family: 'Roboto', sans-serif;
  color: #606062;
  font-size: 16px;
  line-height: 18px;
  margin-left: 2px;
  margin-bottom: ${(props) => (props?.mb ? props.mb : '-5px')};
  margin-top: ${(props) => (props?.mt ? props.mt : '0px')};
  font-weight: 700;
  span {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 16px;
`;

export const WrapperLink = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const SelectiveProcessWrapper = styled.div`
  margin-top: ${(props) => (props.hasMargin ? '2rem' : '0')};
`;

export const WrapperJobInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;
export const ContainerSelectiveProcess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
`;
