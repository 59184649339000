import React, { useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FieldLabel, TextSubtitle, TextTitle } from '../../../styled';
import {
  AbsenceContent,
  AbsenceItemsWrapper,
  AbsenceItemWrapper,
  DateFieldWrapper,
} from './styled';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import DeleteIcon from 'assets/academicModeling/delete';
import {
  AddButtonWrapper,
  DeleteScheduleButton,
} from '../SchedulingTab/styled';
import { HiPlus } from 'react-icons/hi';
import { useCreateMentor } from '../../../context';
import { Scope } from '@unform/core';
import { mentorshipApi } from '../../../../../../../services/api';
import ModalInformation from 'components/informationModal';
import moment from 'moment';

export function AbsencesTab() {
  const [isLoadingAbsences, setIsLoadingAbsences] = useState(true);
  const [
    indexMentorHasMentorshipsModal,
    setIndexMentorHasMentorshipsModal,
  ] = useState(null);

  const {
    absencesRef,
    newAbsencesAmount,
    mentor,
    isEdit,
    absencesForDelete,
    addToAbsencesForDeleteList,
    validateAbsences,
    filteredAbsences,
    incrementAbsenceAmount,
    decrementAbsenceAmount,
  } = useCreateMentor();

  useEffect(() => {
    if (isEdit && isLoadingAbsences) {
      const newAbsencesFormData = [];
      if (mentor?.absences) {
        filteredAbsences.forEach((absence, index) => {
          newAbsencesFormData[index] = {
            start_date: new Date(absence.start_date),
            end_date: new Date(absence.end_date),
          };
        });
        const data = absencesRef.current.getData();
        absencesRef.current.setData({
          ...data,
          savedAbsences: newAbsencesFormData,
        });
      }
    }
    setIsLoadingAbsences(false);
  }, [mentor, isLoadingAbsences, filteredAbsences]);

  const currentDate = new Date();

  currentDate.setDate(currentDate.getDate() + 1);
  const exampleInitialDate = currentDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  currentDate.setDate(currentDate.getDate() + 8);
  const exampleFinalDate = currentDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const validateAbsenceItem = async (absence, index) => {
    try {
      const schema = Yup.object().shape({
        start_date: Yup.date()
          .typeError('Este campo é obrigatório')
          .test('is-null', 'Este campo é obrigatório', function (start_date) {
            if (
              this.parent.end_date &&
              index !== indexMentorHasMentorshipsModal
            ) {
              return !!start_date;
            }
            return true;
          })
          .required('Este campo é obrigatório'),
        end_date: Yup.date()
          .typeError('Este campo é obrigatório')
          .test(
            'is-greater',
            'A data final deve ser maior que a data inicial',
            function (end_date) {
              if (
                this.parent.start_date &&
                index !== indexMentorHasMentorshipsModal
              ) {
                return end_date >= this.parent.start_date;
              }
              return true;
            }
          )
          .test('is-null', 'Este campo é obrigatório', function (end_date) {
            if (
              this.parent.start_date &&
              index !== indexMentorHasMentorshipsModal
            ) {
              return !!end_date;
            }
            return true;
          })
          .required('Este campo é obrigatório'),
      });
      absencesRef.current.setFieldError(`newAbsences[${index}].end_date`, '');
      absencesRef.current.setFieldError(`newAbsences[${index}].start_date`, '');
      return await schema.validate(absence, {
        abortEarly: false,
      });
    } catch (err) {
      const validationErrors = absencesRef?.current?.getErrors();
      if (validationErrors && err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[`newAbsences[${index}].${error.path}`] =
            error.message;
        });
        absencesRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleUpdateAbsence = async (e, index, field) => {
    const data = absencesRef.current.getData();
    const formItem = data.newAbsences[index];
    if (field === 'start_date') {
      formItem.start_date = e;
    } else {
      formItem.end_date = e;
    }
    if (!isLoadingAbsences) {
      const validatedData = await validateAbsenceItem(formItem, index);
      if (isEdit && validatedData?.start_date && validatedData?.end_date) {
        const startDateFormatted = moment(validatedData.start_date).format(
          'YYYY-MM-DD'
        );
        const endDateFormatted = moment(validatedData.end_date).format(
          'YYYY-MM-DD'
        );
        const responseHasMentorships = await mentorshipApi.verifyIfMentorHasScheduledMentorshipInDateRange(
          mentor.id,
          startDateFormatted,
          endDateFormatted
        );
        if (responseHasMentorships) {
          setIndexMentorHasMentorshipsModal(index);
        }
      }
    }
  };

  const buildAbsenceItem = (context, index) => {
    return (
      <AbsenceItemWrapper key={index}>
        <Scope path={`${context}[${index}]`}>
          <DateFieldWrapper>
            <FieldLabel marginBottom="0">De</FieldLabel>
            <DatePickerCustom
              title={
                context === 'savedAbsences'
                  ? 'Não é possível editar uma ausência já previamente cadastrada. Caso necessário, ' +
                    'você pode optar por deletar a ausência que desaja editar e cadastrar uma nova. '
                  : null
              }
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              name="start_date"
              placeholder={`Ex: ${exampleInitialDate}`}
              handleUpdate={
                context === 'newAbsences'
                  ? (e) => handleUpdateAbsence(e, index, 'start_date')
                  : null
              }
              disabled={context === 'savedAbsences'}
              isClearable={context === 'newAbsences'}
            />
          </DateFieldWrapper>
          <DateFieldWrapper>
            <FieldLabel marginBottom="0">Até</FieldLabel>
            <DatePickerCustom
              title={
                context === 'savedAbsences'
                  ? 'Não é possível editar uma ausência já previamente cadastrada. Caso necessário, ' +
                    'você pode optar por deletar a ausência que desaja editar e cadastrar uma nova. '
                  : null
              }
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              name="end_date"
              placeholder={`Ex: ${exampleFinalDate}`}
              handleUpdate={
                context === 'newAbsences'
                  ? (e) => handleUpdateAbsence(e, index, 'end_date')
                  : null
              }
              disabled={context === 'savedAbsences'}
              isClearable={context === 'newAbsences'}
            />
          </DateFieldWrapper>
          <DeleteScheduleButton
            type={'button'}
            onClick={() => removeOneAbsence(context, index)}
          >
            <DeleteIcon color={'#606062'} size={18} />
          </DeleteScheduleButton>
        </Scope>
      </AbsenceItemWrapper>
    );
  };

  const addOneAbsence = () => {
    incrementAbsenceAmount();
  };

  const removeOneAbsence = (context, index) => {
    const deleteNewAbsence = () => {
      const data = absencesRef.current.getData();
      let newAbsences = data.newAbsences?.filter(
        (_, absenceIndex) => index !== absenceIndex
      );
      if (
        newAbsences.length === 0 &&
        absencesForDelete.length + 1 === mentor?.absences.length
      ) {
        newAbsences = [
          {
            start_date: '',
            end_date: '',
          },
        ];
      }
      absencesRef.current.setData({ ...data, newAbsences });
      decrementAbsenceAmount();
      validateAbsences({ newAbsences });
    };
    if (isEdit) {
      if (context === 'savedAbsences') {
        if (
          absencesForDelete.length + 1 === mentor.absences.length &&
          newAbsencesAmount === 0
        ) {
          incrementAbsenceAmount();
        }
        addToAbsencesForDeleteList(filteredAbsences[index].id);
      } else {
        deleteNewAbsence();
      }
    } else {
      deleteNewAbsence();
    }
  };

  const configHasMentorshipScheduledModal = {
    bubbleText: 'Atenção!',
    ParagraphText: `O mentor já possui mentorias agendadas para os períodos de ausência informados.`,
    TwoParagraphText: `Tem certeza de que deseja prosseguir? Todas as mentorias agendadas nesse período serão automaticamente canceladas.`,
    buttonConfirmText: 'SIM, QUERO PROSSEGUIR',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '20px',
      lineHeight: '24px',
      bubbleWidth: '161px',
      sizeIconPerson: '78px',
      bubbleMargin: '4px 0',
      bubbleFontWeight: '400',
    },
  };

  const handleConfirmCancelMentorships = async () => {
    setIndexMentorHasMentorshipsModal(null);
  };

  const handleDenyCancelMentorships = async () => {
    absencesRef.current?.setFieldValue(
      `newAbsences[${indexMentorHasMentorshipsModal}].end_date`,
      null
    );
    absencesRef.current?.setFieldValue(
      `newAbsences[${indexMentorHasMentorshipsModal}].start_date`,
      null
    );
    setIndexMentorHasMentorshipsModal(null);
  };

  return (
    <Form ref={absencesRef}>
      <TextTitle marginTop={'2rem'}>
        Bloqueio de agenda <strong className={'unbold'}>(opcional)</strong>
      </TextTitle>
      <TextSubtitle marginTop={'1rem'}>
        Utilize essa função para informar suas ausências. Dessa forma, os
        estudantes não poderão agendar um horário nos dias selecionados.
      </TextSubtitle>
      <AbsenceContent>
        <AbsenceItemsWrapper>
          {isEdit &&
            Array.from({ length: filteredAbsences.length }, (_, index) =>
              buildAbsenceItem('savedAbsences', index)
            )}
          {Array.from({ length: newAbsencesAmount }, (_, index) =>
            buildAbsenceItem('newAbsences', index)
          )}
        </AbsenceItemsWrapper>
        <AddButtonWrapper marginTop={'39px'}>
          <HiPlus color={'#009291'} size={18} onClick={addOneAbsence} />
        </AddButtonWrapper>
      </AbsenceContent>
      <ModalInformation
        modalOpenState={indexMentorHasMentorshipsModal !== null}
        config={configHasMentorshipScheduledModal}
        buttonConfirmFunction={() => handleConfirmCancelMentorships()}
        buttonCancelFunction={() => handleDenyCancelMentorships()}
      />
    </Form>
  );
}
