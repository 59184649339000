import styled from 'styled-components';

export const ContainerTitles = styled.div``;

export const H5 = styled.h5`
  font-weight: 600;
  font-size: 16px;
  color: #6d6d6d;

  span > span {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const P = styled.p`
  margin-top: -5px;
`;
