import React, { useContext, useEffect } from 'react';
import { Container, Wrapper, CloseButton } from './principalModalStyle';

import X from '../../../../../assets/Oportutinites/X.svg';
import { JobOfferContext } from '../../../Contexts/JobOfferContext';

export default function PrincipalModal({
  children,
  openModal,
  closeModal,
  zIndex,
}) {
  const { resetAllData } = useContext(JobOfferContext);

  useEffect(() => {
    if (openModal === false) {
      resetAllData();
    }
  }, [openModal]);

  return (
    <Container openModal={openModal} zIndex={zIndex}>
      <Wrapper>
        <CloseButton onClick={closeModal}>
          <img src={X} />
        </CloseButton>
        {children}
      </Wrapper>
    </Container>
  );
}
