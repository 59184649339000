import { useVisualizationContext } from '../../../Contexts/VisualizationContext';
import { H4Header, ContainerMiddleInformations } from '../styled';
import ReactHtmlParser from 'react-html-parser';
import { BsLink45Deg } from 'react-icons/bs';
import Button from '../../Button';

export default function Programation() {
  const { detailsObject, finishedType, copyLinkToClipboard } = useVisualizationContext();

  const { id, event_schedule } = detailsObject;

  return (
    <>
      <H4Header>Programação do evento</H4Header>

      <p>{ReactHtmlParser(event_schedule)}</p>

      {finishedType !== "canceled" &&
        <ContainerMiddleInformations>
          <div>
            <div>
              <BsLink45Deg size={30} color="#009291" />

              <span>
                <h6>Link da publicação</h6>
                <Button
                  onClick={() => copyLinkToClipboard(id)}
                  theme="flatUniversityColor"
                  >
                  Clique para copiar
                </Button>
              </span>
            </div>
          </div>
        </ContainerMiddleInformations>
      }
    </>
  );
}
