import defineApiUrl from 'utils/defineApiUrl';
import defineDataProductsUrl from 'utils/defineDataProductsUrl';
import defineUrlFront from '../utils/defineUrlFront';
import {
  defineInternshipBackUrl,
  defineInternshipFrontUrl,
} from '../utils/defineInternshipUrl';

export default {
  API_URL: defineApiUrl(),
  DATA_PRODUCTS_URL: defineDataProductsUrl(),
  MOCK_SERVER: 'https://mockdna.herokuapp.com',
  LOGIN_URLS: {
    '/login/:universidade/aluno': 'internal/new-authenticate/students',
    '/login/:universidade/matriculados': 'internal/new-authenticate/students',
    '/login/:universidade/egressos': 'internal/new-authenticate/students',
    '/vaga/:universidade/:id': 'internal/new-authenticate/students',
    '/feiraseeventos/:universidade/:fairEventId': 'internal/new-authenticate/students',
    '/aprendizagem/:universidade/compartilhar/evento/:eventId/:registerId?':
      'internal/new-authenticate/students',
    '/aprendizagem/:universidade/compartilhar/evento/:eventId':
      'internal/new-authenticate/students',
    '/feiras-e-eventos/:id/:uuid': 'internal/authenticate/universities',
    '/login/universidade': 'internal/authenticate/universities',
    '/trabalhabilidade': 'internal/authenticate/universities',
    '/login/empresa': 'internal/authenticate/companies',
    '/login/backoffice': 'internal/authenticate/backoffice',
  },
  REDIRECT_URLS: {
    student: '#/login/:universidade/aluno',
    done: '#/login/:universidade/egressos',
    enrolled: '#/login/:universidade/matriculados',
    university: '#/login/universidade',
    company: '#/login/empresa',
    backoffice: '#/login/backoffice',
  },
  API_INTERNSHIP_URL: defineInternshipBackUrl(),
  FRONT_INTERNSHIP_URL: defineInternshipFrontUrl(),
  URL_FRONT: defineUrlFront(),
};
