import styled from 'styled-components';

export const SubTitle = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 34px;
`;

export const formSubTitle = [
  (() => (
    <SubTitle style={{ textAlign: 'center' }}>
      A vaga será publicada para alunos de uma instituição específica?
    </SubTitle>
  ))(),
  'Selecione a atuação/cargo mais semelhante à sua vaga.',
  'Agora só falta completar as informações específicas de sua vaga.',
  'Agora só falta completar as informações específicas de sua vaga.',
];
