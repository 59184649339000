import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';

import { IoIosArrowBack } from 'react-icons/io';
import {
  ContainerInfo,
  Header,
  Container,
  BackButton,
  ButtonDetailsModal,
  TextName,
  Box,
  ContainerContent,
  TextParagraph,
  BoxModal,
  ContainerColumn,
  ContentIcon,
  ContainerCenter,
  ContainerHeaderExternal,
  ContainerIconAndText,
  TextCode,
} from '../../JobDetails/styled';
import { SkeletonDetails } from '../../JobDetails/SkeletonDetails';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import ModalInformation from 'components/informationModal';

export function DetailsExternalJobs({
  jobId,
  handleCloseModal,
  universityColor,
  config,
}) {
  const { IconSuitcase, IconCompany, IconHouse } = wrapperIconsJobOffers();
  const [openModalExternalJob, setOpenModalExternalJob] = useState(false);
  const [detailsExternalJobs, setDetailsExternalJobs] = useState([]);
  const [selectUrl, setSelectUrl] = useState(null);

  const fetchDetailsExclusiveJobOffers = async (jobId) => {
    const data = await JobOffersApi.getDetailsExternalJobOffers(jobId);
    setDetailsExternalJobs && setDetailsExternalJobs(data);
  };
  useEffect(() => {
    fetchDetailsExclusiveJobOffers(jobId);
  }, []);

  const detailsJob = detailsExternalJobs?.data;

  const returnSalary = () => {
    if (
      detailsJob.compensation === '0.00' ||
      detailsJob.compensation === ' ' ||
      detailsJob.compensation === ''
    ) {
      return 'Não informado';
    } else {
      return detailsJob.compensation;
    }
  };

  const openExternalModal = (url) => {
    setOpenModalExternalJob(true);
    setSelectUrl(url);
  };

  const handleExternalApply = (url) => {
    window.open(url, '_blank');
  };

  const handleCloseModalExternalApply = () => {
    setOpenModalExternalJob(false);
  };

  return (
    <>
      {detailsJob === null || detailsJob === undefined ? (
        <SkeletonDetails
          handleCloseModal={handleCloseModal}
          universityColor={universityColor}
          hasIcon={true}
        />
      ) : (
        <BoxModal>
          {openModalExternalJob && (
            <ModalInformation
              universityColor={universityColor}
              modalOpenState={openModalExternalJob}
              config={config}
              buttonCancelFunction={handleCloseModalExternalApply}
              buttonConfirmFunction={() => handleExternalApply(selectUrl)}
              closeModalFunction={handleCloseModalExternalApply}
            />
          )}

          <Container>
            <ContainerInfo>
              <ContainerHeaderExternal>
                <BackButton
                  onClick={(e) => handleCloseModal(e)}
                  color={universityColor}
                >
                  <IoIosArrowBack />
                  <p>Voltar</p>
                </BackButton>
              </ContainerHeaderExternal>

              <Header>
                <TextCode isCode>CÓDIGO: {detailsJob.job_id}</TextCode>
                <TextName>{detailsJob.title}</TextName>
              </Header>

              <Box>
                <ContainerContent>
                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconCompany color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>{detailsJob.company_name}</TextParagraph>
                  </ContainerIconAndText>

                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconHouse color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>{detailsJob.job_type}</TextParagraph>
                  </ContainerIconAndText>

                  <ContainerIconAndText>
                    <ContentIcon universityColor={universityColor}>
                      <IconSuitcase color={universityColor} />
                    </ContentIcon>
                    <TextParagraph>
                      {detailsJob.city}/{detailsJob.state}
                    </TextParagraph>
                  </ContainerIconAndText>
                </ContainerContent>

                {detailsJob.description ? (
                  <ContainerContent>
                    <ContainerColumn>
                      <TextParagraph isTitleDetails>
                        Descrição da vaga
                      </TextParagraph>
                      <TextParagraph>
                        {ReactHtmlParser(detailsJob.description)}
                      </TextParagraph>
                    </ContainerColumn>
                  </ContainerContent>
                ) : null}

                <ContainerContent>
                  <ContainerColumn>
                    <TextParagraph isTitleDetails>Faixa salarial</TextParagraph>
                    <TextParagraph>{returnSalary()}</TextParagraph>
                  </ContainerColumn>
                </ContainerContent>

                <ContainerCenter>
                  <ButtonDetailsModal
                    color={universityColor}
                    onClick={() => openExternalModal(detailsJob.url)}
                  >
                    Acessar vaga
                  </ButtonDetailsModal>
                </ContainerCenter>
              </Box>
            </ContainerInfo>
          </Container>
        </BoxModal>
      )}
    </>
  );
}
