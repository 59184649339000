import React from 'react';

const RiBuilding4Line = ({ width = 55, height = 50, color = '#009291' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      fill={color}
      d="M50 45.817h5v5H0v-5h5v-42.5a2.5 2.5 0 0 1 2.5-2.5h40a2.5 2.5 0 0 1 2.5 2.5zm-5 0v-40H10v40zm-27.5-22.5H25v5h-7.5zm0-10H25v5h-7.5zm0 20H25v5h-7.5zm12.5 0h7.5v5H30zm0-10h7.5v5H30zm0-10h7.5v5H30z"
    ></path>
  </svg>
);

export default RiBuilding4Line;
