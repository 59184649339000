import React, { Fragment, useContext, useEffect, useRef } from 'react';
import PublishedCarousel from './Carousel';

import {
  ArrowButton,
  Container,
  Content,
  Divider,
  ListPublishedProjects,
  SubTitle,
  Title,
  Wrapper,
} from './styled';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
  scrollLeftInProgress,
  scrollLeftOpenForSubscriptions,
  scrollLeftWaitingForStart,
  scrollRigthInProgress,
  scrollRigthOpenForSubscriptions,
  scrollRigthWaitingForStart,
} from './utils/logicScroll';
import { ExtensionProjectsContext } from '../../../context/ExtensionProjectsContext';
import { formatNumber } from 'utils/formatNumber';
import GenericMessage from 'components/GenericMessage';
import { handleCreateExtensionProject } from '../../../../Opportunities/utils/handleFunctions';
import { OportunitiesContext } from '../../../../Opportunities/Context/OportunitiesContext';

export default function PublishedProjects(props) {
  const { activeTab, setStatus } = props;
  const {
    futureExtension,
    inProgressExtension,
    openForSubscriptionsExtension,

    setFutureExtension,
    setInProgressExtension,
    setOpenForSubscriptionsExtension,

    paginationOneCount,
    paginationTwoCount,
    paginationThreeCount,

    setPaginationOneCurrentPage,
    setPaginationTwoCurrentPage,
    setPaginationThreeCurrentPage,
    setActiveTab,

    countInProgressProjects,
    countFutureProjects,
    countOpenedProjects,
  } = useContext(ExtensionProjectsContext);

  const {
    setModalIsOpen,
    setModalContent,
  } = useContext(OportunitiesContext);

  const waitingForStart = true;

  const openForSubscriptions = useRef();
  const futureProjects = useRef();
  const inProgressContainer = useRef();

  useEffect(() => {
    setFutureExtension(null);
    setInProgressExtension(null);
    setOpenForSubscriptionsExtension(null);

    setPaginationOneCurrentPage(0);
    setPaginationTwoCurrentPage(0);
    setPaginationThreeCurrentPage(0);
  }, [activeTab]);

  useEffect(() => {
    let intersectionObserver;
    if (openForSubscriptionsExtension !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (openForSubscriptionsExtension.length > 7) {
            setPaginationOneCurrentPage(
              (currentPageInsideState) => currentPageInsideState + 8
            );
          }
        }
      });

      if (openForSubscriptionsExtension.length === paginationOneCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (openForSubscriptionsExtension.length > 0) {
        intersectionObserver?.observe(openForSubscriptions.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [openForSubscriptionsExtension, paginationOneCount]);

  useEffect(() => {
    let intersectionObserver;
    if (futureExtension !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (futureExtension.length > 7) {
            setPaginationTwoCurrentPage(
              (currentPageInsideState) => currentPageInsideState + 8
            );
          }
        }
      });

      if (futureExtension.length === paginationTwoCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (futureExtension.length > 0) {
        intersectionObserver?.observe(futureProjects.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [futureExtension, paginationTwoCount]);

  useEffect(() => {
    let intersectionObserver;
    if (inProgressExtension !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (inProgressExtension.length > 7) {
            setPaginationThreeCurrentPage(
              (currentPageInsideState) => currentPageInsideState + 8
            );
          }
        }
      });

      if (inProgressExtension.length === paginationThreeCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (inProgressExtension.length > 0) {
        intersectionObserver?.observe(inProgressContainer.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [inProgressExtension, paginationThreeCount]);

  return (
    <Container>
      <Content>
        {inProgressExtension !== null &&
        inProgressExtension.length === 0 &&
        openForSubscriptionsExtension !== null &&
        openForSubscriptionsExtension.length === 0 &&
        futureExtension !== null &&
        futureExtension.length === 0 ? (
          <GenericMessage
            title="Não identificamos nenhum Projeto de Extensão publicado!"
            buttonLabel="Adicionar Projeto de Extensão"
            buttonFunc={() => 
              handleCreateExtensionProject(
                setModalIsOpen,
                setModalContent,
              )
            }
          />
        ) : (
          <Fragment>
            <Wrapper>
              <Title marginTop="14px">
                Inscrições abertas ({formatNumber(countOpenedProjects)})
              </Title>
              <SubTitle>
                Aqui você pode ver seus projetos de extensão que estão
                disponíveis para seus estudantes se inscreverem.
              </SubTitle>
              {openForSubscriptionsExtension !== null &&
              openForSubscriptionsExtension.length === 0 ? (
                <GenericMessage
                  title="Não identificamos nenhum Projeto de Extensão publicado!"
                  buttonLabel="Adicionar Projeto de Extensão"
                  buttonFunc={() => {
                    handleCreateExtensionProject(
                      setModalIsOpen,
                      setModalContent,
                    );
                  }}
                />
              ) : (
                <Fragment>
                  <ListPublishedProjects id="open-for-subscriptions">
                    <PublishedCarousel
                      setStatus={setStatus}
                      array={openForSubscriptionsExtension}
                    />
                    <li ref={openForSubscriptions} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton onClick={scrollLeftOpenForSubscriptions}>
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton onClick={scrollRigthOpenForSubscriptions}>
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Fragment>
              )}
            </Wrapper>
            <Divider></Divider>

            <Wrapper>
              <Title>
                Aguardando início ({formatNumber(countFutureProjects)})
              </Title>
              <SubTitle>
                Aqui você pode ver seus projetos de extensão que estão
                aguardando a data de início do projeto e não possuem vagas
                disponíveis para inscrição.
              </SubTitle>
              {futureExtension !== null && futureExtension.length === 0 ? (
                <GenericMessage
                  title="Não identificamos nenhum Projeto de Extensão aguardando
                início!"
                />
              ) : (
                <Fragment>
                  <ListPublishedProjects id="waiting-for-start">
                    <PublishedCarousel
                      array={futureExtension}
                      waitingForStart={waitingForStart}
                      setStatus={setStatus}
                    />
                    <li ref={futureProjects} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton onClick={scrollLeftWaitingForStart}>
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton onClick={scrollRigthWaitingForStart}>
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Fragment>
              )}
            </Wrapper>
            <Divider></Divider>

            <Wrapper>
              <Title>
                Em andamento ({formatNumber(countInProgressProjects)})
              </Title>
              <SubTitle>
                Aqui você pode ver seus projetos de extensão que estão em
                andamento.
              </SubTitle>
              {inProgressExtension !== null &&
              inProgressExtension.length === 0 ? (
                <GenericMessage title="Não identificamos nenhum Projeto de Extensão em andamento!" />
              ) : (
                <Fragment>
                  <ListPublishedProjects id="in-progress">
                    <PublishedCarousel
                      setStatus={setStatus}
                      array={inProgressExtension}
                    />
                    <li ref={inProgressContainer} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton onClick={scrollLeftInProgress}>
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton onClick={scrollRigthInProgress}>
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Fragment>
              )}
            </Wrapper>
          </Fragment>
        )}
      </Content>
    </Container>
  );
}
