import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import bag from '../../../../assets/JobOffer/bag.svg';
import {
  Container,
  ContainerSelectiveProcess,
  Header,
  HeaderTitle,
  SelectiveProcessWrapper,
  Wrapper,
  WrapperJobInfo,
  WrapperLink,
  WrapperPeriod,
  Title,
} from './style';
import Input from '../../../../views/StaffPages/Opportunities/Components/Form/input';
import DatePickerCustom from '../../../StaffPages/Opportunities/Components/Form/datePicker';
import Radio from '../Form/radioInput';
import InputDesc from '../../../StaffPages/Opportunities/Components/Form/inputDesc';
import SearchableSelectUn from '../Form/searchableSelect';
import { JobOfferContext } from '../../Contexts/JobOfferContext';
import {
  colorOrRace,
  disability,
  genderIndentity,
  jobType,
  publicationType,
} from './useCases/SelectOptions';
import {
  createJobOffer,
  editJobOffer,
  getCourses,
  getUniversities,
  getCoursesByUniversity,
} from '../../services';

import * as Yup from 'yup';
import { payloadCreateExternalJob } from './helpers/payloadCreateExternalJob';
import { login } from '../../../../services/login/authenticate';
import { schemaValidation } from './useCases/schemaValidationJob';

import {
  IModalConfigCreateCompany,
  IModalConfigCreateCompanyError,
  IModalConfigCreateJob,
  IModalConfigCreateJobError,
  IModalConfigCreateJobErrorInternal,
  IModalConfigCreateJobInternal,
  IModalConfigEditJobErrorInternal,
  IModalConfigEditJobInternal,
} from '../JobOfferForm/Steps/useCases/IModalConfig';
import {
  CheckboxWrapper,
  CompletedButton,
  ContainerButtons,
  ContainerSpecificsUniversities,
  FooterForm,
  CustomCepAddresInput,
} from '../JobOfferForm/Steps/style';
import { PreviewButtonExternal } from '../JobOfferForm/Steps/Buttons/PreviewButtonExternal';
import { modalityType } from '../JobOfferForm/Steps/useCases/SelectOptions';
import LoadingModal from '../LoadingModal';
import { PrevsButton } from 'views/StaffPages/Opportunities/Styles/formStyled';
export default function ExternalJobOffer() {
  const {
    companyData,
    data: contextData,
    setData,
    ExternalJobRef,
    dataFunction,
    cacheContext,
    handleTabChange,
    metadata,
    setShowInformationModalMyProfile,
    selectedUniversity,
    prevsJobOfferStep,
  } = useContext(JobOfferContext);
  const [selectiveProcess, setSelectiveProcess] = useState(false);
  const [justForSomeUniversities, setJustForSomeUniversities] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [isFetchingUniversities, setIsFetchingUniversities] = useState(false);
  const [courses, setCourses] = useState([]);
  const [firstLoadUniversity, setFirstLoadUniversity] = useState(true);

  const [currentModalConfig, setCurrentModalConfig] = useState(
    IModalConfigCreateCompany
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [onUserAuthenticate, setOnUserAuthenticate] = useState({});

  const [showAddress, setShowAddress] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState([]);
  const [modalityChangesHistory, setModalityChangesHistory] = useState([]);
  const [
    modalityDefaultValuesObject,
    setModalityDefaultValuesObject,
  ] = useState(null);

  useEffect(() => {
    if (
      contextData?.application_type &&
      contextData.application_type.length > 0
    ) {
      setSelectedApplication(contextData.application_type);
      ExternalJobRef.current.setFieldValue(
        'application_type',
        contextData.application_type
      );
    }
  }, [contextData]);

  useEffect(() => {
    if (
      modalityChangesHistory?.length === 0 &&
      contextData?.modality_type?.value
    ) {
      setModalityChangesHistory([
        ...modalityChangesHistory,
        contextData?.modality_type?.value,
      ]);
    }
  }, [contextData]);

  const handleSelectModality = (e) => {
    const value = e.value;

    if (modalityChangesHistory[modalityChangesHistory?.length - 1] !== value) {
      setModalityChangesHistory([...modalityChangesHistory, value]);
    }

    if (value === 'hybrid' || value === 'presential') {
      return setShowAddress(true);
    }
    setShowAddress(false);
  };

  const handleSelectPublicationType = (e) => {
    const value = e.value;
    if (value === 'link') {
      setData((prev) => ({
        ...prev,
        job_email: '',
      }));
      setShowLink(true);
      setShowEmail(false);
      ExternalJobRef.current.setFieldValue('job_email', '');
    }
    if (value === 'email') {
      setData((prev) => ({
        ...prev,
        divulgation_link: '',
      }));
      setShowLink(false);
      setShowEmail(true);
      ExternalJobRef.current.setFieldValue('divulgation_link', '');
    }
  };

  useEffect(() => {
    setShowAddress(
      contextData?.modality_type &&
        contextData?.modality_type !== 'remote' &&
        contextData?.modality_type?.value !== 'remote'
    );
  }, [contextData, cacheContext]);

  useEffect(() => {
    if (contextData && contextData.job_email) {
      ExternalJobRef.current.setFieldValue('publication_type', {
        value: 'email',
        label: 'E-mail de direcionamento',
      });
      setShowLink(false);
      setShowEmail(true);
    }

    if (contextData && contextData.divulgation_link) {
      ExternalJobRef.current.setFieldValue('publication_type', 'link');
      ExternalJobRef.current.setFieldValue('publication_type', {
        value: 'link',
        label: 'Link de direcionamento',
      });
      setShowLink(true);
      setShowEmail(false);
    }
  }, [contextData, cacheContext]);

  async function fetchUniversities(courses) {
    setIsFetchingUniversities(true);
    let query = '';
    if (courses && courses.length > 0) {
      query = `courses=${courses.join('&courses=')}`;
    }
    const response = await getUniversities(query);
    if (response?.status === 200) {
      setUniversities(response.data);
    }
    setIsFetchingUniversities(false);
    return response.data;
  }

  useEffect(() => {
    if (
      contextData.enabled_just_for_universities &&
      contextData.enabled_just_for_universities?.length > 0
    ) {
      setJustForSomeUniversities(true);
    }
  }, [contextData, cacheContext]);

  const radioOptions = [
    {
      id: '1',
      value: true,
      label: `Quero promover a diversidade e inclusão de pessoas
      levando em consideração raça, identidade
      de gênero e pessoa com deficiência.`,
      type: 'checkbox',
    },
  ];

  const handleUserLogin = (loginUser) => {
    if (onUserAuthenticate.isLogged || loginUser.isLogged) {
      companyData.functionToLogin(
        onUserAuthenticate.data ? onUserAuthenticate.data : loginUser.data
      );
    }
  };

  const handleSetModalState = () => {
    setModalOpen(false);
  };

  const returnCurrentFunction = () => {
    if (currentModalConfig === IModalConfigCreateJobErrorInternal) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigCreateJobError) {
      return handleUserLogin();
    }
    if (currentModalConfig === IModalConfigCreateCompanyError) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigEditJobErrorInternal) {
      return handleSetModalState();
    }
  };

  const handleSelectSelectionProcess = (e) => {
    setData((prev) => ({
      ...prev,
      administrative_selection_process: e.target ? e.target.checked : false,
      disability: [],
      color_or_race: [],
      gender_identity: [],
    }));
    ExternalJobRef.current.setFieldValue('disability', []);
    ExternalJobRef.current.setFieldValue('color_or_race', []);
    ExternalJobRef.current.setFieldValue('gender_identity', []);
    if (e.target.checked) {
      setSelectiveProcess(() => true);
      return;
    }
    setSelectiveProcess(() => false);
  };

  useEffect(() => {
    const getCoursesAPI = async () => {
      const response = await getCourses();
      const coursesOptions = await verifySpecificUniversityCourses(response);
      setCourses(coursesOptions);
    };
    getCoursesAPI();
  }, []);

  const verifySpecificUniversityCourses = async (courses) => {
    if (selectedUniversity && selectedUniversity !== undefined) {
      const response = await getCoursesByUniversity(
        selectedUniversity.map((i) => i.value)
      );
      const getUniversityCourses = () =>
        new Promise((resolve, reject) => {
          const arrayCourses = [];
          courses.forEach((item, index) => {
            if (
              response.courses?.some(
                (course) => course.principal_course__id === item.value
              )
            ) {
              arrayCourses.push(item);
            }
            if (index + 1 === courses.length) {
              resolve(arrayCourses);
            }
          });
        });

      const universityCourses = await getUniversityCourses();

      return universityCourses;
    }
    return courses;
  };

  const editJob = async (data) => {
    setModalOpen(true);
    setCurrentModalConfig(IModalConfigEditJobInternal);

    const responseEdit = await editJobOffer(
      contextData.jobId,
      payloadCreateExternalJob(data)
    );

    if (responseEdit.message) {
      return setCurrentModalConfig(IModalConfigEditJobErrorInternal);
    }

    if (contextData.isInternalCompany) {
      setModalOpen(false);
      dataFunction.handlePageChange('limit=0');
      setData({});
      dataFunction.setOpenModalPublishJob(false);
    }
  };

  const createJob = async (data) => {
    setModalOpen(true);
    setCurrentModalConfig(IModalConfigCreateJobInternal);

    const responseJOB = await createJobOffer(payloadCreateExternalJob(data));

    if (responseJOB.message) {
      return setCurrentModalConfig(IModalConfigCreateJobErrorInternal);
    }

    if (contextData.isInternalCompany) {
      await dataFunction.handlePageChange('limit=0');
      setModalOpen(false);
      setData({});
      dataFunction.setOpenModalPublishJob(false);
    }

    if (!contextData.isInternalCompany) {
      handleUserLogin();
    }
  };

  const createCompanyAndPublicateJob = async (data) => {
    setModalOpen(true);

    const loginUrl = '/login/empresa';
    const userData = {
      username: companyData.staff_email,
      password: companyData.staff_password,
      company: true,
    };

    const loginUser = await login(loginUrl, userData);

    setOnUserAuthenticate(loginUser);
    setCurrentModalConfig(IModalConfigCreateJob);

    const responseJOB = await createJobOffer(payloadCreateExternalJob(data));

    if (responseJOB.message) {
      return setCurrentModalConfig(IModalConfigCreateJobError);
    }

    window.location.reload();
  };

  const handleSubmit = async (data) => {
    const formattedData = {
      ...data,
      start_offer_at: moment(data.start_offer_at).format('YYYY-MM-DD'),
      end_offer_at: moment(data.end_offer_at).format('YYYY-MM-DD'),
      enabled_just_for_universities: contextData.enabled_just_for_universities
        ? Array.isArray(contextData.enabled_just_for_universities)
          ? contextData.enabled_just_for_universities
          : [contextData.enabled_just_for_universities]
        : [],
    };
    ExternalJobRef.current.setErrors({});

    try {
      await schemaValidation.validate(formattedData, {
        abortEarly: false,
      });

      if (!contextData.isInternalCompany) {
        return await createCompanyAndPublicateJob(formattedData);
      }
      if (!contextData.isEditJob) {
        await createJob(formattedData);
        handleTabChange(_, 0);
        if (metadata.is_profile_incomplete) {
          setShowInformationModalMyProfile(true);
        }
        return;
      }

      await editJob(formattedData);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        ExternalJobRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (contextData.isEditJob) {
      ExternalJobRef.current.setFieldValue(
        'application_type',
        contextData.application_type
      );
      ExternalJobRef.current.setFieldValue('courses', contextData.courses);
      ExternalJobRef.current.setFieldValue(
        'modality_type',
        contextData.modality_type
      );

      if (contextData.administrative_selection_process) {
        setSelectiveProcess(true);
      }
      fetchUniversities(contextData.courses.map((course) => course.value));
    }
  }, [contextData, cacheContext]);

  function toggleForSpecificsUniversities() {
    setJustForSomeUniversities(!justForSomeUniversities);
    if (justForSomeUniversities) {
      contextData.enabled_just_for_universities = null;
    }
  }

  const onChangeCourses = async (courses) => {
    if (justForSomeUniversities) {
      const coursesIds = courses.map((course) => course.value);
      const universitiesResponse = await fetchUniversities(coursesIds);
      if (!universitiesResponse) return;
      const formUniversities = ExternalJobRef.current.getFieldRef(
        'enabled_just_for_universities'
      );
      if (!formUniversities || !formUniversities.state.value) return;
      const filteredUniversities = formUniversities.state.value.filter(
        (university) => {
          return universitiesResponse.some(
            (universityResponse) =>
              universityResponse.value === university.value
          );
        }
      );
      ExternalJobRef.current.setFieldValue(
        'enabled_just_for_universities',
        filteredUniversities
      );
    }
  };

  useEffect(() => {
    if (justForSomeUniversities) {
      const courses = ExternalJobRef.current.getFieldValue('courses');
      fetchUniversities(courses);
    }
  }, [justForSomeUniversities]);

  useEffect(() => {
    if (
      contextData &&
      contextData.enabled_just_for_universities &&
      firstLoadUniversity
    ) {
      if (contextData.enabled_just_for_universities.length > 0) {
        if (justForSomeUniversities) {
          ExternalJobRef.current.setFieldValue(
            'enabled_just_for_universities',
            contextData.enabled_just_for_universities
          );
          setFirstLoadUniversity(false);
        }
      } else {
        setFirstLoadUniversity(false);
      }
    }
  }, [contextData, firstLoadUniversity, justForSomeUniversities]);

  useEffect(() => {
    if (selectedApplication.length > 1) {
      const lastOption = selectedApplication[selectedApplication.length - 1];
      setSelectedApplication([lastOption]);
      ExternalJobRef.current.setFieldValue('application_type', [lastOption]);
    }
  }, [selectedApplication]);

  const handleApplicationTypeSelect = (options) => {
    setSelectedApplication(options);
  };

  const getAddressDefaultValues = () => {
    let arrayLength = modalityChangesHistory?.length || 0;

    if (
      modalityChangesHistory &&
      modalityChangesHistory[arrayLength - 2] &&
      modalityChangesHistory[arrayLength - 2] === 'remote' &&
      !modalityDefaultValuesObject
    ) {
      let returningObject = {
        zip_code: '',
        logradouro: '',
        address_number: '',
        address_complement: '',
        bairro: '',
        uf: '',
        localidade: '',
      };
      setModalityDefaultValuesObject(returningObject);
      return returningObject;
    }

    if (modalityDefaultValuesObject) {
      return modalityDefaultValuesObject;
    } else {
      return {
        zip_code: contextData.zip_code,
        logradouro: contextData.address,
        address_number: contextData.address_number,
        address_complement: contextData.address_complement,
        bairro: contextData.district,
        uf: contextData.state,
        localidade: contextData.city,
      };
    }
  };

  return (
    <Fragment>
      <LoadingModal
        modalOpenState={modalOpen}
        config={currentModalConfig}
        buttonFunction={returnCurrentFunction}
      />

      <Wrapper>
        <Form
          ref={ExternalJobRef}
          onSubmit={handleSubmit}
          initialData={contextData}
        >
          <Header>
            <img src={bag} />
            <HeaderTitle>Divulgar processo seletivo</HeaderTitle>
          </Header>
          <p>
            Agora só falta completar as <strong>informações específicas</strong>{' '}
            de sua vaga.
          </p>

          <Input
            name="name"
            placeholder="Digite aqui..."
            label={
              <Title mb="10px">
                Nome da vaga* <span>(Obrigatório)</span>
              </Title>
            }
          />

          <SearchableSelectUn
            name="application_type"
            label={
              <Title mb="10px" mt="16px">
                Tipo de vaga* <span>(Obrigatório)</span>
              </Title>
            }
            isClearable
            isMulti
            value={selectedApplication}
            onChange={handleApplicationTypeSelect}
            options={jobType}
          />

          <WrapperLink>
            <SearchableSelectUn
              name="publication_type"
              label={
                <Title mb="10px">
                  Tipo de publicação* <span>(Obrigatório)</span>
                </Title>
              }
              options={publicationType}
              onChange={handleSelectPublicationType}
            />

            {showLink && (
              <Input
                name="divulgation_link"
                placeholder="Insira o link que o estudante irá acessar ao se candidatar"
              />
            )}
            {showEmail && (
              <Input
                name="job_email"
                placeholder="Insira o e-mail que o estudante irá acessar ao se candidatar"
              />
            )}
          </WrapperLink>

          <InputDesc
            name="description"
            placeholder="Digite aqui"
            label={
              <Title mb="10px" mt="16px">
                Descrição da vaga <span>(Opcional)</span>
              </Title>
            }
          />

          <SearchableSelectUn
            name="courses"
            label={
              <Title mb="10px" mt="16px">
                Cursos <span>(Opcional)</span>
              </Title>
            }
            isClearable
            isMulti
            options={courses}
            onChange={onChangeCourses}
          />

          <WrapperJobInfo>
            <SearchableSelectUn
              name="modality_type"
              label={
                <Title mb="10px">
                  Modalidade de trabalho* <span>(Obrigatório)</span>
                </Title>
              }
              options={modalityType}
              onChange={handleSelectModality}
            />
            {showAddress && (
              <CustomCepAddresInput
                fieldsProps={{
                  label: (
                    <>
                      CEP* <span>(Obrigatório)</span>
                    </>
                  ),
                  address: {
                    label: (
                      <>
                        Rua/Avenida* <span>(Obrigatório)</span>
                      </>
                    ),
                  },
                  addressNumber: {
                    label: (
                      <>
                        Número* <span>(Obrigatório)</span>
                      </>
                    ),
                  },
                  addressComplement: {
                    label: (
                      <>
                        Complemento <span>(Opcional)</span>
                      </>
                    ),
                  },
                  addressDistrict: {
                    label: (
                      <>
                        Bairro* <span>(Obrigatório)</span>
                      </>
                    ),
                  },
                  city: {
                    label: (
                      <>
                        Cidade* <span>(Obrigatório)</span>
                      </>
                    ),
                  },
                  state: {
                    label: (
                      <>
                        UF* <span>(Obrigatório)</span>
                      </>
                    ),
                  },
                }}
                formRef={ExternalJobRef}
                defaultValues={getAddressDefaultValues()}
                fieldsProps={{className: 'job-offers-field'}}
              />
            )}
          </WrapperJobInfo>

          <WrapperPeriod>
            <Title mb="2px">
              Período de Divulgação* <span>(Obrigatório)</span>
            </Title>
            <Container>
              <DatePickerCustom
                minDate={new Date()}
                name="start_offer_at"
                placeholder="De"
              />
              <DatePickerCustom
                minDate={new Date()}
                name="end_offer_at"
                placeholder="Até"
              />
            </Container>
          </WrapperPeriod>

          <SelectiveProcessWrapper hasMargin={!justForSomeUniversities}>
            <Title mb="10" mt="24px">
              Processo Seletivo Afirmativo <span>(Opcional)</span>
            </Title>
            <Radio
              name="administrative_selection_process"
              options={radioOptions}
              onChange={handleSelectSelectionProcess}
              checked={selectiveProcess}
            />

            {selectiveProcess && (
              <ContainerSelectiveProcess>
                <SearchableSelectUn
                  name="disability"
                  isClearable
                  isMulti
                  label={
                    <Title mb="10">
                      PCD - Selecione possíveis deficiências{' '}
                      <span>(Opcional)</span>
                    </Title>
                  }
                  options={disability}
                />
                <SearchableSelectUn
                  name="color_or_race"
                  isClearable
                  label={
                    <Title mb="10">
                      Raça/Cor <span>(Opcional)</span>
                    </Title>
                  }
                  isMulti
                  options={colorOrRace}
                />
                <SearchableSelectUn
                  name="gender_identity"
                  isClearable
                  isMulti
                  label={
                    <Title mb="10">
                      Identidade de Gênero <span>(Opcional)</span>
                    </Title>
                  }
                  options={genderIndentity}
                />
              </ContainerSelectiveProcess>
            )}
          </SelectiveProcessWrapper>
          <FooterForm>
            <PrevsButton type="button" onClick={prevsJobOfferStep} />
            <ContainerButtons>
              <PreviewButtonExternal
                courses={courses}
                handleSubmit={handleSubmit}
                universities={universities}
              >
                Pré-Visualizar Vaga
              </PreviewButtonExternal>
              <CompletedButton> Publicar </CompletedButton>
            </ContainerButtons>
          </FooterForm>
        </Form>
      </Wrapper>
    </Fragment>
  );
}
