import styled from 'styled-components';

export const InputRadio = styled.input`
  accent-color: #009291;
  margin: 0 0.5rem 0 0 !important;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const LabelInput = styled.label`
  display: inline-block;
  align-items: center;
  font-size: 15px;
  color: #4b4b4d;
  margin-left: 10px;
`;

export const SubTitle = styled.p`
  margin-left: 40px;
  font-size: 14px;
  color: #606062;
  margin-top: 10px;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
`;
