import React, { useContext, useRef } from 'react';
import {
  WrapperModal,
  DivContainer,
  StyledHeader,
  ContainerForm,
  WrapperButton,
} from './style';
import { Form } from '@unform/web';
import Radio from '../../Form/radioInput';
import { JobOfferContext } from '../../../Contexts/JobOfferContext';
import { NextButton } from '../../../../StaffPages/Opportunities/Styles/formStyled';
import { useOutSideClick } from '../../../hooks/useOutsideClick';
import RightArrow from 'assets/Oportutinites/RightArrow';

export default function JobOfferPublicationTypeModal({
  openModal,
  setOpenModal,
  isInternalCompany,
  setOpenPublishJobModal,
  openModalPublishJob,
}) {
  const formRef = useRef(null);

  const DivContainerRef = useRef(null);

  const { setExternalJobOffer, NextStepRegister, setFormValues } = useContext(
    JobOfferContext
  );

  const [clickedOutside, SetClickedOutside] = useOutSideClick(DivContainerRef);

  if (clickedOutside) {
    setOpenModal(false);
    SetClickedOutside(false);
  }

  const radioOptions = [
    {
      id: 'inteligence',
      value: 'inteligence',
      label: 'Publicar vaga com Inteligência',
      description: `Você receberá uma sugestão de texto para
        preenchimento de sua vaga, conforme dados do mercado.
        Edite de acordo com a sua necessidade.`,
      type: 'radio',
    },
    {
      id: 'external',
      value: 'external',
      label: 'Somente divulgar meu Processo Seletivo',
      description: `Somente utilize essa opção se você já 
      cadastrou seu processo seletivo em outra plataforma. 
      Vamos pedir o link ou e-mail do seu processo seletivo.`,
      type: 'radio',
    },
  ];

  const handleSubmit = (data) => {
    if (data.typeSubmit !== '') {
      if (data.typeSubmit === 'external') {
        setExternalJobOffer(true);
      } else {
        setExternalJobOffer(false);
      }
      formRef.current.clearField('typeSubmit');
      formRef.current.reset();
      NextStepRegister();
      setOpenModal(false);

      if (isInternalCompany) {
        setFormValues({
          isInternalCompany,
          setOpenPublishJobModal,
          openModalPublishJob,
        });
        return setOpenPublishJobModal(true);
      }
    }
  };

  return (
    <WrapperModal isOpen={openModal}>
      <DivContainer ref={DivContainerRef}>
        <StyledHeader>Tipo de Divulgação</StyledHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ContainerForm>
            <Radio name="typeSubmit" options={radioOptions} />
            <WrapperButton>
              <NextButton type="submit">
                <RightArrow />
              </NextButton>
            </WrapperButton>
          </ContainerForm>
        </Form>
      </DivContainer>
    </WrapperModal>
  );
}
