import GenericModal from 'views/StaffPages/Mentorship/components/GenericModal';
import { useCreateMentor } from '../../../../context';
import { Container, SaveButton } from './styled';
import { FieldLabel, Form } from '../../../../styled';
import React, { useEffect, useMemo, useState } from 'react';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import {
  AddButtonWrapper,
  Dash,
  DeleteScheduleButton,
  HourInput,
  HourInputWrapper,
  HoursContainer,
  ScheduleError,
  ScheduleFieldsWrapper,
  SchedulesContainer,
  WrapperLabel,
} from '../styled';
import { maskTime, maskTimeBlur } from 'utils/inputMasks';
import { Scope } from '@unform/core';
import {
  transformKeysToObject,
  transformObjectToKeys,
} from 'utils/unformUtils';
import { HiPlus } from 'react-icons/hi';
import DeleteIcon from 'assets/academicModeling/delete';
import { mentorshipApi } from '../../../../../../../../services/api';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export const SpecificDayModal = (props) => {
  const { isOpen, onClose } = props;
  const {
    formData,
    specificDateRef,
    validateHourRangeSpecificModal,
    handleSubmitSpecificDate,
    validateScheduleSpecificDate,
    scheduleSpecificModalError: scheduleError,
    setScheduleSpecificModalError: setScheduleError,
    scheduleAmountSpecificDate: scheduleAmount,
    setScheduleAmountSpecificDate: setScheduleAmount,
    mentor,
    setMentor,
    setFormData,
    schedulingRef,
    isEdit,
  } = useCreateMentor();
  const [dayIndex, setDayIndex] = useState(null);

  const specificDates = useMemo(() => formData.specific_dates, [formData]);

  useEffect(() => {
    validateHourRangeSpecificModal();
  }, [scheduleAmount]);

  const currentDate = new Date();

  currentDate.setDate(currentDate.getDate() + 1);
  const exampleInitialDate = currentDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const handleClose = () => {
    specificDateRef.current.reset();
    specificDateRef.current.setErrors({});
    setScheduleAmount(1);
    setScheduleError('');
    setDayIndex(null);
    onClose();
  };

  const handleSubmit = async () => {
    if (!isEdit || (isEdit && dayIndex < null)) {
      const submittedWithSuccess = await handleSubmitSpecificDate(dayIndex);
      if (!submittedWithSuccess) {
        return;
      }
    }
    if (isEdit) {
      setMentor({ ...mentor, specific_dates: specificDates });
    }
    handleClose();
  };

  const calculateEndHour = (index, time) => {
    const value = maskTimeBlur(time);
    if (!value) {
      specificDateRef.current.setFieldValue(`schedules[${index}].end_hour`, '');
      return;
    }
    let [hours, minutes] = value.split(':');
    let newHours = (parseInt(hours) + 1) % 24;
    newHours = newHours < 10 ? `0${newHours}` : newHours;
    specificDateRef.current.setFieldValue(
      `schedules[${index}].end_hour`,
      `${newHours}:${minutes}`
    );
  };

  const addOneSchedule = () => {
    setScheduleAmount(scheduleAmount + 1);
  };

  const handleDeleteOneSchedule = (index, scheduleId) => {
    const updateScheduleArray = (schedules, index) => {
      schedules.splice(index, 1);
      setScheduleAmount(scheduleAmount - 1);
    };

    let currentSchedules;
    if (isEdit && dayIndex >= 0) {
      currentSchedules = specificDates[dayIndex].schedules;
      if (scheduleId) {
        mentorshipApi.deleteSpecificDate(mentor.id, scheduleId);
      }
      updateScheduleArray(currentSchedules, index);
      specificDates[dayIndex].schedules = currentSchedules;
      setMentor({ ...mentor, specific_dates: specificDates });
    } else {
      currentSchedules = specificDateRef.current.getData()['schedules'];
      updateScheduleArray(currentSchedules, index);
      currentSchedules.forEach((schedule, index) => {
        specificDateRef.current.setFieldValue(
          `schedules[${index}].start_hour`,
          schedule.start_hour
        );
        specificDateRef.current.setFieldValue(
          `schedules[${index}].end_hour`,
          schedule.end_hour
        );
      });
    }
    const errors = specificDateRef.current.getErrors();
    const objectErrors = transformKeysToObject(errors);
    if (objectErrors['schedules']) {
      objectErrors['schedules'].splice(index, 1);
      specificDateRef.current.setErrors(transformObjectToKeys(objectErrors));
    }
  };

  const foundedSpecificDate = useMemo(() => {
    if (specificDates && dayIndex >= 0) {
      return specificDates[dayIndex];
    }
    return null;
  }, [specificDates, dayIndex]);

  useEffect(() => {
    if (foundedSpecificDate) {
      const schedules = foundedSpecificDate.schedules;
      schedules.forEach((schedule, index) => {
        specificDateRef.current.setFieldValue(
          `schedules[${index}].start_hour`,
          schedule.start_hour
        );
        specificDateRef.current.setFieldValue(
          `schedules[${index}].end_hour`,
          schedule.end_hour
        );
      });
    }
  }, [foundedSpecificDate, scheduleAmount]);

  const handleBlurStartHour = async (event, indexSchedule, scheduleId) => {
    const value = maskTimeBlur(event.target.value);
    event.target.value = value;
    const isValid = validateScheduleSpecificDate(
      indexSchedule,
      event.target.value
    );
    if (isValid && dayIndex >= 0) {
      try {
        let [hours, minutes] = value.split(':');
        let newHours = (parseInt(hours) + 1) % 24;
        newHours = newHours < 10 ? `0${newHours}` : newHours;
        const endHour = `${newHours}:${minutes}`;
        if (isEdit) {
          const newSpecificDate = {
            mentor: mentor.id,
            specific_dates: [
              {
                date: specificDates[dayIndex].date,
                start_hour: value,
                end_hour: endHour,
              },
            ],
          };
          if (scheduleId) {
            mentorshipApi.deleteSpecificDate(mentor.id, scheduleId);
          }
          const response = await mentorshipApi.createSpecificDates(
            newSpecificDate
          );
          const id = response?.dates[0].id;
          const newSpecificDates = [...specificDates];
          newSpecificDates[dayIndex].schedules[indexSchedule] = {
            start_hour: value,
            end_hour: endHour,
            id,
          };
          const newFormData = { ...formData };
          newFormData.specific_dates = newSpecificDates;
          setMentor({ ...mentor, specific_dates: newSpecificDates });
          setFormData(newFormData);
        }
        schedulingRef.current.setFieldValue(
          `specific_dates[${dayIndex}].schedules[${indexSchedule}].start_hour`,
          value
        );
        schedulingRef.current.setFieldValue(
          `specific_dates[${dayIndex}].schedules[${indexSchedule}].end_hour`,
          endHour
        );
      } catch (e) {
        customSnackbar(
          'Ocorreu um error ao atualizar as datas específicas',
          'error'
        );
      }
    }
  };

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Incluir um dia e horário específico"
    >
      <Container>
        <Form ref={specificDateRef}>
          <FieldLabel>Escolha uma data</FieldLabel>
          <DatePickerCustom
            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
            name="date"
            placeholder={`Ex: ${exampleInitialDate}`}
            handleUpdate={(date) => {
              if (!date) return;
              const foundedSpecificDate = specificDates.findIndex(
                (specificDate) => {
                  return (
                    new Date(specificDate.date).toLocaleDateString('pt-BR') ===
                    date.toLocaleDateString('pt-BR')
                  );
                }
              );
              if (foundedSpecificDate >= 0) {
                setScheduleAmount(
                  specificDates[foundedSpecificDate].schedules.length
                );
              }
              setDayIndex(foundedSpecificDate);
            }}
          />
          <WrapperLabel>
            <FieldLabel marginTop={'2rem'} width={'fit-content'}>
              Horário{scheduleAmount > 1 && 's'}
            </FieldLabel>
            {scheduleError && (
              <ScheduleError className={'error-label'}>
                {scheduleError}
              </ScheduleError>
            )}
          </WrapperLabel>
          <SchedulesContainer marginTop={'12px'}>
            <ScheduleFieldsWrapper>
              {Array.from({ length: scheduleAmount }).map((_, index) => (
                <HoursContainer key={index}>
                  <Scope path={`schedules[${index}]`}>
                    <HourInputWrapper>
                      <HourInput
                        name={'start_hour'}
                        placeholder={'00:00'}
                        maxLength={5}
                        hasMask
                        showErrorLabel={false}
                        onChange={(event) => {
                          const maskedValue = maskTime(event.target.value);
                          specificDateRef.current.setFieldValue(
                            `schedules[${index}].start_hour`,
                            maskedValue
                          );
                          calculateEndHour(index, maskedValue);
                        }}
                        onBlur={(event) =>
                          handleBlurStartHour(
                            event,
                            index,
                            foundedSpecificDate &&
                              foundedSpecificDate.schedules[index]
                              ? foundedSpecificDate.schedules[index].id
                              : null
                          )
                        }
                      />
                    </HourInputWrapper>
                    <Dash />
                    <HourInputWrapper>
                      <HourInput
                        showErrorLabel={false}
                        name={'end_hour'}
                        placeholder={'00:00'}
                        maxLength={5}
                        disabled
                      />
                    </HourInputWrapper>
                    {scheduleAmount > 1 && (
                      <DeleteScheduleButton
                        type={'button'}
                        onClick={() =>
                          handleDeleteOneSchedule(
                            index,
                            foundedSpecificDate
                              ? foundedSpecificDate.schedules[index]?.id
                              : null
                          )
                        }
                      >
                        <DeleteIcon color={'#606062'} size={18} />
                      </DeleteScheduleButton>
                    )}
                  </Scope>
                </HoursContainer>
              ))}
            </ScheduleFieldsWrapper>
            {scheduleAmount < 24 && (
              <AddButtonWrapper
                paddingBottom={scheduleAmount > 1 ? '2rem' : '1rem'}
              >
                <HiPlus
                  color={'#009291'}
                  size={18}
                  onClick={() => addOneSchedule()}
                />
              </AddButtonWrapper>
            )}
          </SchedulesContainer>
          <SaveButton type={'button'} onClick={handleSubmit}>
            Salvar
          </SaveButton>
        </Form>
      </Container>
    </GenericModal>
  );
};
