import React, { useState } from 'react';

import { Container, ContainerDate } from './styled';
import { StepTitle } from '../styled';
import Input from 'components/UnformFields/inputField';
import { Form } from '@unform/web';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import StepController from '../../StepController';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import { FormHandlers } from 'views/StaffPages/FairAndEvents/Handlers/formHandlers';
import { getValidationSchema } from './schema';
import formValidationHandler from 'views/StaffPages/FairAndEvents/Handlers/formValidationHandler';
import Tooltip from 'components/NewTooltip';
import { HintInformation } from 'components/UnformFields/Select/styled';
import { FieldLabel, InputFieldWrapper, WrapperLabelAndHint } from '../StepTwo/styled';

export default function StepThree() {
  const useRegisterFormInstance = useRegisterModalContext();

  const FormHandlersFactory = FormHandlers(useRegisterFormInstance);

  const { stepsData, formRefStepThree } = useRegisterFormInstance;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { event_start_at, event_end_at } = stepsData;

  const schema = getValidationSchema(event_start_at, event_end_at);

  const handleSubmit = (data) => {
    FormHandlersFactory.handleSubmitForm(data, schema, formRefStepThree);
  };

  setInterval(async () => {
    const formData = formRefStepThree?.current?.getData();

    const newSchema = formValidationHandler.convertSchema(schema);

    try {
      await newSchema.validate(formData, { abortEarly: false });
      setIsButtonDisabled(false);
    } catch (err) {
      setIsButtonDisabled(true);
    }
  }, 1000);

  return (
    <Container>
      <StepTitle>Detalhes da inscrição</StepTitle>

      <Form
        ref={formRefStepThree}
        onSubmit={handleSubmit}
        initialData={stepsData}
      >
        <ContainerDate>
          <DatePickerCustom
            name="registration_start_date"
            label="Data de início*"
          />
          <DatePickerCustom
            name="registration_end_date"
            label="Data de término*"
          />
        </ContainerDate>
        <InputFieldWrapper>
          <WrapperLabelAndHint>
            <Tooltip
              text={
                `Caso não seja informado um limite de estudantes ou ele
              seja 0, entenderemos que o evento está aberto a um número
              ilimitado de participantes.`
              }
              direction={'top'}
              minWidth={'300px'}
              isMultiLine
            >
              <HintInformation>i</HintInformation>
            </Tooltip>
            <FieldLabel>
              Limite de estudantes
            </FieldLabel>
          </WrapperLabelAndHint>
          <Input
            name="limit_students"
            formatDecimal={true}
            removeMargin
            labelClassName="fair-and-events-input"
          />
        </InputFieldWrapper>
        <StepController isButtonDisabled={isButtonDisabled} />
      </Form>
    </Container>
  );
}
