import React, { useEffect, useRef, useState } from 'react';
import search from 'assets/FairAndEvents/search.svg';

import { useField } from '@unform/core';
import {
  UnInput,
  UnInputLabel,
  ContainerLabelInput,
  Error,
} from './style';
import MaskedInput from '../MaskedInput';

export default function MaskInput({
  label,
  name,
  maxLength,
  onChange,
  useCount,
  width,
  padding,
  removeMargin,
  onClick,
  mask = '11111-111',
  ...rest
}) {
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [value, setValue] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',

      setValue: (ref, value) => {
        ref.value = value ? value : '';
        setValue(value.replace('-', ''));
      },
    });
  }, [fieldName, registerField]);

  const handleChange = (event, useDefault) => {
    const value = useDefault ? event : event.target.value;

    if (onChange) {
      onChange(event);
    }
    setValue(value);
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue, true);
    }
  }, []);

  return (
    <ContainerLabelInput style={{ width: width }} removeMargin>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <div>
        <MaskedInput
          error={error}
          mask={mask}
          fieldRef={inputRef}
          maxLength={maxLength}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          style={{ padding: padding }}
          {...rest}
        >
          <UnInput />
        </MaskedInput>
        <img src={search} onClick={onClick} alt="search" />
      </div>

      {error && <Error>{error}</Error>}
    </ContainerLabelInput>
  );
}
