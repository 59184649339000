import React, { useContext, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import SearchableSelectUn from '../../Form/searchableSelect';
import {
  ContainerSpecificsUniversities,
  FooterForm,
  RadioButtonWrapper,
  StepExclusiveIesContainer,
} from './style';
import { JobOfferContext } from '../../../Contexts/JobOfferContext';
import { getUniversities } from '../../../services';
import {
  NextButton,
  ErrorMessage,
} from '../../../../StaffPages/Opportunities/Styles/formStyled';
import RiBuilding4Line from 'assets/JobOffer/RiBuilding4Line';
import RiBuildingLine from 'assets/JobOffer/RiBuildingLine';
import RightArrow from 'assets/Oportutinites/RightArrow';

export default function StepExclusiveIES() {
  const {
    nextJobOfferStep,
    setFormValues,
    data,
    StepExclusiveIESRef,
    selectedUniversity,
    setSelectedUniversity,
    justForSomeUniversities,
    setJustForSomeUniversities,
  } = useContext(JobOfferContext);

  const [universities, setUniversities] = useState([]);
  const [isFetchingUniversities, setIsFetchingUniversities] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (data?.isEditJob) {
      if (
        Array.isArray(data?.enabled_just_for_universities ?? null) &&
        data.enabled_just_for_universities.length > 0
      ) {
        setSelectedUniversity(data.enabled_just_for_universities);
        setJustForSomeUniversities(true);
      } else {
        setJustForSomeUniversities(false);
      }
    }
  }, [data]);

  async function fetchUniversities() {
    setIsFetchingUniversities(true);
    const response = await getUniversities();
    setUniversities(response.data);
    setIsFetchingUniversities(false);
  }

  useEffect(() => {
    fetchUniversities();
  }, []);

  const handleSubmit = async (formData) => {
    const newErrors = {};

    if (justForSomeUniversities === null) {
      newErrors.publishScope = 'Você precisa selecionar uma opção.';
    }

    if (justForSomeUniversities && !selectedUniversity) {
      newErrors.enabled_just_for_universities = 'Selecione a faculdade.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      setErrors({});
      setFormValues(formData);
      nextJobOfferStep();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
      setErrors(validationErrors);
    }
  };

  return (
    <StepExclusiveIesContainer>
      <Form
        ref={StepExclusiveIESRef}
        onSubmit={handleSubmit}
        initialData={data}
      >
        <RadioButtonWrapper>
          <input
            id="specificUniversities"
            type="radio"
            name="publishScope"
            onChange={() => {
              setJustForSomeUniversities(true);
              setErrors((prev) => ({ ...prev, publishScope: '' }));
            }}
            checked={justForSomeUniversities === true}
          />
          <label htmlFor="specificUniversities">
            <RiBuilding4Line className="icon" />
            <h4>Específica</h4>
            <p>
              Publicar a vaga para <b>instituição de ensino específica.</b>
            </p>
          </label>

          <input
            id="allUniversities"
            type="radio"
            name="publishScope"
            onChange={() => {
              setJustForSomeUniversities(false);
              setSelectedUniversity(null);
              setErrors((prev) => ({ ...prev, publishScope: '' }));
            }}
            checked={justForSomeUniversities === false}
          />
          <label htmlFor="allUniversities">
            <RiBuildingLine className="icon" />

            <h4>Geral</h4>
            <p>
              Publicar a vaga para{' '}
              <b>todas as instituições de ensino parceiras.</b>
            </p>
          </label>
        </RadioButtonWrapper>
        {errors.publishScope && (
          <ErrorMessage>{errors.publishScope}</ErrorMessage>
        )}

        {justForSomeUniversities && (
          <ContainerSpecificsUniversities>
            <p>Selecione a instituição de ensino desejada.</p>
            <SearchableSelectUn
              name="enabled_just_for_universities"
              options={universities}
              isDisabled={isFetchingUniversities}
              isLoading={isFetchingUniversities}
              isCleareble
              onChange={(value) => {
                setSelectedUniversity(value);
                setErrors((prev) => ({
                  ...prev,
                  enabled_just_for_universities: '',
                }));
              }}
              className="inputIes"
              isMulti
              value={selectedUniversity}
            />
            {errors.enabled_just_for_universities && (
              <ErrorMessage>
                {errors.enabled_just_for_universities}
              </ErrorMessage>
            )}
          </ContainerSpecificsUniversities>
        )}

        <FooterForm>
          <NextButton
            type="submit"
            disabled={
              justForSomeUniversities === null ||
              (justForSomeUniversities === true &&
                (selectedUniversity === null || selectedUniversity.length == 0))
            }
          >
            <RightArrow
              color={justForSomeUniversities === null ? '#949494' : '#FFF'}
            />
          </NextButton>
        </FooterForm>
      </Form>
    </StepExclusiveIesContainer>
  );
}
