import React, { useEffect, useState } from 'react';

import {
  Container,
  ContainerAddress,
  WrapperRowsInput,
  FieldLabel,
  InputFieldWrapper,
  WrapperLabelAndHint,
} from './styled';
import { StepTitle } from '../styled';
import { Form } from '@unform/web';

import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import Input from 'components/UnformFields/inputField';
import InputWorkload from 'components/UnformFields/InputWorkload';
import StepController from '../../StepController';
import { FormHandlers } from 'views/StaffPages/FairAndEvents/Handlers/formHandlers';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import { staticOptions } from 'views/StaffPages/FairAndEvents/StaticOptions';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import FairAndEventsService from '../../../Services/index';
import MaskInput from 'components/UnformFields/MaskInput';
import HourPicker from 'components/UnformFields/Hourpicker';
import { schema as schemaPresential, schemaOnline } from './schema';
import formValidationHandler from 'views/StaffPages/FairAndEvents/Handlers/formValidationHandler';
import { HintInformation } from 'components/UnformFields/Select/styled';
import Tooltip from 'components/NewTooltip';

export default function StepTwo() {
  const useRegisterFormInstance = useRegisterModalContext();

  const {
    selectOptions,
    stepsData,
    formRefStepTwo,
    setStepsData,
  } = useRegisterFormInstance;

  const FormHandlersFactory = FormHandlers(useRegisterFormInstance);

  const [
    isValidAscomplementaryActivity,
    setIsValidAsComplementaryactivity,
  ] = useState(false);
  const [activeDigitalCertificate, setActiveDigitalCertificate] = useState(
    false,
  );
  const [tick, setTick] = useState(0);
  const [modality, setModality] = useState('');
  const [cep, setCep] = useState('');
  const [hasCepError, setHasCepError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const hasModalitySelected = modality.length > 0;
  const showCepField = modality === 'presential';
  const showAddressFields = cep.replace('_', '').length === 9;

  const handleSelectComplementaryActivity = (event) => {
    const value = event.value;
    setIsValidAsComplementaryactivity(value);

    if (!value) {
      formRefStepTwo.current.setFieldValue('complementary_activity_time', '');

      if (stepsData.complementary_activity_time) {
        const { complementary_activity_time, ...rest } = stepsData;

        setStepsData(rest);
      }
    }
  };

  useEffect(() => {
    if (stepsData.event_modality) {
      setModality(stepsData.event_modality);
    }
  }, [stepsData]);

  const handleSelectActivateDigitalCertificate = (event) => {
    const value = event.value;
    setActiveDigitalCertificate(value);

    if (!value) {
      formRefStepTwo.current.setFieldValue('certification_time', '');

      if (stepsData.certification_time) {
        const { certification_time, ...rest } = stepsData;

        setStepsData(rest);
      }
    }
  };

  const handleSelectModality = (event) => {
    const modality = event.value;
    setModality(modality);

    if (modality === 'online' && cep.length > 0) {
      setCep('');
    }
  };

  const getDataByZipCode = async () => {
    const response = await FairAndEventsService.getDataByZipCode(cep);
    setHasCepError(false);
    formRefStepTwo.current.setErrors({});
    if (response.erro) {
      formRefStepTwo.current.setFieldError(
        'zip_code',
        'Informe um CEP válido.',
      );
      setHasCepError(true);
      return;
    }

    if (response.logradouro.length > 0) {
      formRefStepTwo.current.setFieldValue('street', response.logradouro);
    }

    if (response.bairro.length > 0) {
      formRefStepTwo.current.setFieldValue('district', response.bairro);
    }

    if (response.localidade.length > 0) {
      formRefStepTwo.current.setFieldValue('city', response.localidade);
    }

    if (response.uf.length > 0) {
      formRefStepTwo.current.setFieldValue('state', response.uf);
    }
  };

  const handleChangeCep = (event) => {
    const cep = event?.target?.value || event;
    setCep(cep);
  };

  useEffect(() => {
    if (cep.replace('_', '').length === 9) {
      getDataByZipCode();
    }
  }, [cep]);

  const controllCepField = () => {
    if (hasModalitySelected) {
      if (showCepField) {
        return (
          <InputFieldWrapper gap={'8px'}>
            <FieldLabel>CEP*</FieldLabel>
            <MaskInput
              name="zip_code"
              width="100%"
              height={'37px'}
              maxLength={8}
              value={cep}
              removeMargin
              onChange={handleChangeCep}
              onClick={getDataByZipCode}
            />
          </InputFieldWrapper>
        );
      }
      return (
        <InputFieldWrapper>
          <FieldLabel>Link para participar do evento*</FieldLabel>
          <Input
            name="event_url"
            width="100%"
            removeMargin
          />
        </InputFieldWrapper>
      );
    }
  };

  useEffect(() => {
    try {
      const formData = formRefStepTwo?.current?.getData();
      const currentSchema =
        modality === 'presential' ? schemaPresential : schemaOnline;

      const newSchema = formValidationHandler.convertSchema(currentSchema);
      newSchema.validateSync(formData, { abortEarly: false });

      return setIsButtonDisabled(false);
    } catch (error) {
      return setIsButtonDisabled(true);
    }
  }, [tick]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((prevs) => prevs + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (data) => {
    const currentSchema =
      modality === 'presential' ? schemaPresential : schemaOnline;

    await FormHandlersFactory.handleSubmitForm(
      data,
      currentSchema,
      formRefStepTwo,
    );
  };

  useEffect(() => {
    if (Object.keys(stepsData).length > 0) {
      formRefStepTwo.current.setData(stepsData);
    }
  }, []);

  return (
    <Container>
      <StepTitle>Sobre o evento</StepTitle>
      <Form
        ref={formRefStepTwo}
        onSubmit={handleSubmit}
        initialData={stepsData}
      >
        <WrapperRowsInput marginTop={'0'}>
          <InputFieldWrapper>
            <FieldLabel>Data de início*</FieldLabel>
            <DatePickerCustom name="event_start_at" />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <FieldLabel>Horário de início*</FieldLabel>
            <HourPicker name="event_start_time" height={'37px'} />
          </InputFieldWrapper>
        </WrapperRowsInput>
        <WrapperRowsInput>
          <InputFieldWrapper>
            <FieldLabel>Data de término*</FieldLabel>
            <DatePickerCustom name="event_end_at" />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <FieldLabel>Horário de término</FieldLabel>
            <HourPicker name="event_end_time" height={'37px'} />
          </InputFieldWrapper>
        </WrapperRowsInput>
        <WrapperRowsInput>
          <InputFieldWrapper gap={'8px'}>
            <FieldLabel>Tipo*</FieldLabel>
            <UnformSelect
              options={selectOptions.event_type}
              name="event_type"
              label=""
              margin="0"
            />
          </InputFieldWrapper>
        </WrapperRowsInput>
        <WrapperRowsInput>
          <InputFieldWrapper gap={'8px'} flex={5}>
            <FieldLabel>Válido como atividade complementar*</FieldLabel>
            <UnformSelect
              options={staticOptions.boolean}
              name="complementary_activity"
              width="100%"
              onChange={handleSelectComplementaryActivity}
            />
          </InputFieldWrapper>
          {isValidAscomplementaryActivity && (
            <InputFieldWrapper gap={'8px'}>
              <FieldLabel>Carga horária*</FieldLabel>
              <InputWorkload
                name="complementary_activity_time"
                width="180px"
                removeMargin
                height="37px"
              />
            </InputFieldWrapper>
          )}
        </WrapperRowsInput>
        <WrapperRowsInput>
          <InputFieldWrapper gap={'8px'} flex={5}>
            <WrapperLabelAndHint>
              <Tooltip
                text={'Gere certificados para os estudantes que participaram do evento'}
                direction={'top'}
                isMultiline={true}
              >
                <HintInformation>i</HintInformation>
              </Tooltip>
              <FieldLabel>
                Habilitar certificado*
              </FieldLabel>
            </WrapperLabelAndHint>
            <UnformSelect
              options={staticOptions.boolean}
              name="certification"
              width="100%"
              onChange={handleSelectActivateDigitalCertificate}
            />
          </InputFieldWrapper>
          {activeDigitalCertificate && (
            <InputFieldWrapper gap={'8px'}>
              <FieldLabel>Carga horária*</FieldLabel>
              <InputWorkload
                name="certification_time"
                label=""
                width="180px"
                removeMargin
                height="37px"
              />
            </InputFieldWrapper>
          )}
        </WrapperRowsInput>
        <WrapperRowsInput>
          <InputFieldWrapper gap={'8px'}>
            <FieldLabel>Modalidade*</FieldLabel>
            <UnformSelect
              onChange={handleSelectModality}
              options={selectOptions.event_modality}
              name="event_modality"
              width="100%"
            />
          </InputFieldWrapper>
          {controllCepField()}
        </WrapperRowsInput>
        {showAddressFields && !hasCepError && (
          <ContainerAddress>
            <div style={{ display: 'flex', gap: '20px', marginTop: '5px' }}>
              <Input
                name="street"
                label="Rua/Avenida*"
                removeMargin
                labelClassName="fair-and-events-input"
              />
              <Input
                name="number"
                label="Número*"
                width="180px"
                removeMargin
                labelClassName="fair-and-events-input"
              />
              <Input
                name="complement"
                label="Complemento"
                width="240px"
                removeMargin
                labelClassName="fair-and-events-input"
              />
            </div>

            <div
              style={{
                display: 'flex',
                gap: '20px',
                marginTop: '10px',
              }}
            >
              <Input
                name="district"
                label="Bairro*"
                width="140px"
                removeMargin
                labelClassName="fair-and-events-input"
              />
              <Input
                name="city"
                label="Cidade*"
                width="350px"
                removeMargin
                labelClassName="fair-and-events-input"
              />
              <Input
                name="state"
                label="UF*"
                width="100px"
                removeMargin
                labelClassName="fair-and-events-input"
              />
            </div>
            <Input
              name="location_name"
              label="Nome do local*"
              useCount
              maxLength={100}
              height="38px"
              labelClassName="fair-and-events-input"
            />
          </ContainerAddress>
        )}
        <StepController isButtonDisabled={isButtonDisabled} />
      </Form>
    </Container>
  );
}
