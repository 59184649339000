import React, { useContext } from 'react';
import {
  ContainerBottomInformations,
  ContainerButtons,
  ContainerFeedBacks,
  ContainerInformationActivities,
  ContainerMiddleInformations,
  CreateByAndDate,
  Divider,
  InformationActivities,
  SpanAll,
  ToDateHeader,
} from '../styled';
import { TbCalendarStats } from 'react-icons/tb';
import Button from '../../Button';

import { useVisualizationContext } from '../../../Contexts/VisualizationContext';
import { MdStars } from 'react-icons/md';
import { HourHelper } from '../../../Helpers/HourHelper';
import { GrCertificate } from 'react-icons/gr';
import { DateHelper } from '../../../Helpers/DateHelper';
import BaseLayoutContext from 'contexts/base-layout';
import ReactHtmlParser from 'react-html-parser';
import { BsBuildings, BsListCheck } from 'react-icons/bs';
import GroupAudience from 'assets/FairAndEvents/GroupAudience';
import moment from 'moment';
import CalendarArrow from 'assets/FairAndEvents/CalendarArrow';
import Building from 'assets/FairAndEvents/Building';
import { BsLink45Deg } from 'react-icons/bs';

export default function AboutEvent({ openTargetAudienceModal }) {
  const {
    detailsObject,
    setStudentViewModalOpen,
    copyLinkToClipboard,
    finishedType,
  } = useVisualizationContext();

  const {
    id,
    name,
    registration_end_date,
    description,
    event_modality,
    event_start_at,
    event_start_time,
    event_end_at,
    complementary_activity,
    complementary_activity_time,
    certification,
    certification_time,
    created_at,
    created_by,
    event_end_time,
    location_name,
    street,
    zip_code,
    number,
    registred_students,
    limit_students,
    macro_areas,
    courses,
    periods,
    registration_start_date,
    complement,
    city,
    state,
  } = detailsObject;

  const labelModality =
    event_modality === 'presential' ? 'Presencial' : 'Online';

  const hasContent = () => {
    return (
      macro_areas?.length > 0 || courses?.length > 0 || periods?.length > 0
    );
  };

  return (
    <>
      {new Date(registration_end_date).getTime() <= new Date().getTime() && (
        <ToDateHeader>
          Inscrições abertas até dia{' '}
          <strong>{moment(registration_end_date).format('DD/MM/YYYY')}!</strong>
        </ToDateHeader>
      )}

      <ContainerInformationActivities>
        {complementary_activity && (
          <InformationActivities>
            <MdStars color="#009291" />
            Atividade complementar -{' '}
            {HourHelper.formatDefault(complementary_activity_time)}
          </InformationActivities>
        )}
        {registred_students < limit_students && (
          <InformationActivities>
            <CalendarArrow color="#009291" size={13} />
            {limit_students - registred_students == 1
              ? `1 vaga restante`
              : `${limit_students - registred_students} vagas restantes`}
          </InformationActivities>
        )}
        {certification && (
          <InformationActivities>
            <GrCertificate color="#009291" />
            Com certificado - {HourHelper.formatDefault(certification_time)}
          </InformationActivities>
        )}
      </ContainerInformationActivities>

      <CreateByAndDate>
        <span>
          Publicado em {DateHelper.format(created_at)} por {created_by}
        </span>
        <h4>{name}</h4>
        <p>{ReactHtmlParser(description)}</p>
      </CreateByAndDate>

      <ContainerMiddleInformations>
        <div>
          <div>
            <TbCalendarStats size={30} color="#009291" />

            <span>
              <h6>Início</h6>
              {DateHelper.format(event_start_at)} às{' '}
              {HourHelper.formatDefault(event_start_time)}
            </span>
          </div>

          <div>
            <BsListCheck size={30} color="#009291" />

            <span>
              <h6>
                {registred_students}{' '}
                {registred_students === 1
                  ? ' estudante inscrito'
                  : ' estudantes inscritos'}
              </h6>
              {registred_students > 0 && (
                <Button
                  theme="flatUniversityColor"
                  onClick={() => setStudentViewModalOpen(true)}
                >
                  Acompanhar
                </Button>
              )}
            </span>
          </div>

          <div>
            <Building size={30} color="#009291" />

            <span>
              <h6>Modalidade</h6>
              {labelModality}
              {event_modality !== 'online' && (
                <>
                  {' '}
                  - {location_name}
                  <br />
                  {street}, {number} - {zip_code}{' '}
                  {complement && `- ${complement}`}
                  <span>{city && state && `${city}/${state}`}</span>
                </>
              )}
            </span>
          </div>
        </div>
        <div>
          <div>
            <TbCalendarStats size={30} color="#009291" />

            <span>
              <h6>Término</h6>
              {DateHelper.format(event_end_at)}
              {event_end_time &&
                ` às ${HourHelper.formatDefault(event_end_time)}`}
            </span>
          </div>

          <div>
            <GroupAudience size={28} color="#009291" />

            <span>
              <h6>Público-alvo</h6>
              {hasContent() ? (
                <Button
                  onClick={openTargetAudienceModal}
                  theme="flatUniversityColor"
                >
                  Clique para ver
                </Button>
              ) : (
                <SpanAll theme="flatUniversityColor">Todos</SpanAll>
              )}
            </span>
          </div>

          {finishedType !== "canceled" &&
            <div>
              <BsLink45Deg size={30} color="#009291" />

              <span>
                <h6>Link da publicação</h6>
                <Button
                  onClick={() => copyLinkToClipboard(id)}
                  theme="flatUniversityColor"
                >
                  Clique para copiar
                </Button>
              </span>
            </div>
          }
        </div>
      </ContainerMiddleInformations>
    </>
  );
}
