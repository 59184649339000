import React from 'react';

const RiBuildingLine = ({ width = 55, height = 50, color = '#009291' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      fill={color}
      d="M48 5.817H23c-2.758 0-5 2.242-5 5v15h-5c-2.758 0-5 2.242-5 5v22.5a2.5 2.5 0 0 0 2.5 2.5h40a2.5 2.5 0 0 0 2.5-2.5v-42.5c0-2.758-2.242-5-5-5m-35 25h15v20H13zm35 20H33v-20c0-2.758-2.242-5-5-5h-5v-15h25z"
    ></path>
    <path
      fill={color}
      d="M28 15.817h5v5h-5zm10 0h5v5h-5zm0 10.077h5v4.923h-5zm0 9.923h5v5h-5zm-20 .002h5v5h-5z"
    ></path>
  </svg>
);

export default RiBuildingLine;
