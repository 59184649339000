import React from 'react';
import { Link } from 'react-router-dom';
import closeIcon from 'assets/Oportutinites/X.svg';

import { CloseButtonStyle } from './style';

export default function LinkButton({ linkTo }) {
  return (
    <CloseButtonStyle>
      <Link
        to={linkTo}
      >
        <img src={closeIcon} />
      </Link>
    </CloseButtonStyle>
  );
}
