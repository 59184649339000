import { Link } from 'react-router-dom';
import { Container, BackButton, TechBy } from './styled';
import image404 from '../../assets/img/404.png';
import logoWL from '../../assets/img/icons/logoWorkaloveLaranja.png';

export default function NotFoundModal({ title, text, titleButton, linkTo }) {
  return (
    <Container>
      <img src={image404} alt='Not found image' className='image'/>
      <p className='legend'>ERRO 404</p>
      <p className='title'>{title}</p>
      <p className='text'>{text}</p>
      <BackButton>
        <Link
          to={linkTo}
        >
          {titleButton}
        </Link>
      </BackButton>
      <TechBy>
        <p>Tecnologia by</p>
        <img src={logoWL} width={77} height={16} />
      </TechBy>
    </Container>
  );
};